import clsx from "clsx";
import useScriptableContent from "hooks/useScriptableContent";
import { marked } from "marked";
import { memo } from "react";

interface Props {
  content?: string;
  className?: string;
  onLoad?: (el: HTMLSpanElement) => void;
}

function MarkdownContent(props: Props) {
  const { content } = props;
  let { className } = props;
  className = clsx(className, "external-content");
  const { onExternalContentLoad } = useScriptableContent();

  function onLoad(el: HTMLSpanElement | null) {
    el && onExternalContentLoad(el);
  }

  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: marked(content ?? "") }}
      ref={onLoad}
    />
  );
}

export default memo(MarkdownContent);
