import AshMutationResult from "components/alerts/AshMutationResult";
import { ErrorMessage, RowLabel, SubmitButton } from "components/form";
import PasswordInput from "components/form/PasswordInput";
import SimpleModal from "components/SimpleModal";
import { useAshMutation } from "hooks/ash";
import useAutoFocus from "hooks/useAutoFocus";
import { UPDATE_SELF_MEMBER2 } from "lib/gf-api/member-api";
import {
  useMember,
  usePasswordSetModalStore,
  useUserStore,
} from "lib/gf-app-context";
import Style from "lib/Style";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PasswordSetForm } from "types/account";

function PasswordSetPrompt() {
  const member = useMember();
  const store = usePasswordSetModalStore();
  const form = useForm<PasswordSetForm>({
    defaultValues: { password: "", passwordConfirmation: "" },
  });
  const { formState, handleSubmit, register } = form;
  const { errors } = formState;
  const [updateAccount, updateAccountState] =
    useAshMutation(UPDATE_SELF_MEMBER2);
  const userStore = useUserStore();
  const focusCloseButton = useAutoFocus();

  useEffect(() => {
    if (
      store &&
      member &&
      member.account.passwordSet === false &&
      member.account.status !== "NON_MEMBER"
    ) {
      store.open();
    }
  }, [member?.account?.passwordSet, store?.isOpen]);

  async function onSubmit(data: PasswordSetForm) {
    if (member?.account?.passwordSet) {
      store?.close();
      return;
    }

    const input = { password: data.password };
    const resp = await updateAccount(
      {
        variables: { input },
      },
      "updateSelfMember2"
    );

    if (!resp.hasError) {
      userStore.loadUser();
      form.reset();
    }
  }

  function validateSameAsPassword(
    passwordConfirmation: string,
    values: PasswordSetForm
  ) {
    const password = values.password;
    return password === passwordConfirmation;
  }

  const passwordSet = member?.account?.passwordSet;

  return (
    <SimpleModal isOpen={store?.isOpen} showCloseButton={false}>
      {member && store && (
        <form className={Style.formClass} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h2 className="h2">Set your password</h2>
            {!passwordSet && (
              <p>Please set a password to secure your account.</p>
            )}
          </div>
          {!passwordSet && (
            <>
              <div>
                <RowLabel>New password</RowLabel>
                <PasswordInput
                  id="field-password"
                  register={register("password", {
                    required: true,
                    minLength: 8,
                    validate: {
                      noLeadingSpace: (value: string) =>
                        !value.match(/^\s+/) && !value.match(/\s+$/),
                    },
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="password"
                  messages={{
                    minLength:
                      "Password must be at least 8 characters in length",
                    noLeadingSpace:
                      "Password must not begin or end with a space",
                  }}
                />
              </div>
              <div>
                <RowLabel>Confirm password</RowLabel>
                <PasswordInput
                  id="field-passwordConfirmation"
                  register={register("passwordConfirmation", {
                    required: true,
                    minLength: 8,
                    validate: {
                      sameAsPassword: validateSameAsPassword,
                    },
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="passwordConfirmation"
                  messages={{
                    sameAsPassword: "Passwords do not match",
                  }}
                />
              </div>
            </>
          )}

          <AshMutationResult
            mutationInfo={updateAccountState}
            successMessage="Your password is set"
          />

          {!passwordSet && (
            <div className="flex flex-col items-center">
              <SubmitButton type="submit" disabled={updateAccountState.loading}>
                Set password
              </SubmitButton>
            </div>
          )}

          {passwordSet && (
            <div className="flex flex-col items-center">
              <SubmitButton type="submit" inputRef={focusCloseButton}>
                Close
              </SubmitButton>
            </div>
          )}
        </form>
      )}
    </SimpleModal>
  );
}

export default observer(PasswordSetPrompt);
