// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  message: React.ReactNode;
}

export default function SimpleFieldError(props: Props) {
  const { className, message } = props;

  return (
    <span className={`text-spp-red ${className || ""}`}>
      {message || "Error"}
    </span>
  );
}
