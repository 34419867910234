// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { useState } from "react";
import { CloseButton } from "./ui";
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  noClose?: boolean;
  onClose?: () => void;
  type?: "info" | "warning";
  className?: string;
  textClass?: string;
}

const Flash = function (props: Props) {
  const { children, noClose } = props;
  const bg = props.type === "warning" ? "bg-warning" : "bg-spp-violet-lightest";
  const typeTextClass = props.type === "warning" ? "text-black" : "text-white";
  const textClass = props.textClass || typeTextClass;
  const c1 = `${bg} rounded flex justify-between items-center px-2 py-1 ${textClass}`;
  const className = `${c1} ${props.className}`;
  const [visible, setVisible] = useState<boolean>(true);

  function onClose() {
    if (props.onClose) {
      props.onClose();
    }

    setVisible(false);
  }

  if (!visible) return null;

  return (
    <div className={className}>
      {children}
      {noClose ? null : (
        <div
          className={clsx(
            "flex transition justify-end hover:text-black",
            textClass
          )}
        >
          <CloseButton
            onClick={onClose}
            textClass=" "
            svgClass="fill-current"
          />
        </div>
      )}
    </div>
  );
};

export default Flash;
