// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ProgressIndicator from "components/ProgressIndicator";
import { NeutralButton } from "components/ui";
import { SyntheticEvent } from "react";
import SubmitButton from "./SubmitButton";

interface Props {
  buttonClass?: string;
  cancel?: { onClick: () => void };
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  marginTopClass?: string;
  onClick?: (e: SyntheticEvent) => void;
  saving?: boolean;
}

export default function SubmitButtonRow(props: Props) {
  const { buttonClass, cancel, children, className, disabled, saving } = props;
  const marginTopClass = props.marginTopClass ?? "mt-4";

  return (
    <div
      className={`flex gap-2 justify-center ${marginTopClass} ${
        className || ""
      }`}
    >
      {cancel && (
        <NeutralButton onClick={() => cancel.onClick()}>Cancel</NeutralButton>
      )}
      {saving && <ProgressIndicator />}
      {!saving && (
        <SubmitButton
          type="submit"
          disabled={disabled || false}
          className={buttonClass}
          loading={saving}
          onClick={props.onClick}
        >
          {children || "Save"}
        </SubmitButton>
      )}
    </div>
  );
}
