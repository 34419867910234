// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { SyntheticEvent } from "react";
import { BadgeType, ControlSize } from "types/ui";

interface Props {
  children: React.ReactNode;
  className?: string;
  marginBottom?: string;
  onClick?: (e: SyntheticEvent) => void;
  style?: any;
  type?: BadgeType;
  size?: ControlSize;
}

export default function Badge(props: Props) {
  const { children, className, onClick, style, size = "md" } = props;
  let bgClass = "bg-gray-light";
  let colorClass = "text-black";
  if (props.type === "primary") {
    bgClass = "bg-blue-600";
    colorClass = "text-white";
  } else if (props.type === "warn") {
    bgClass = "bg-orange-warn";
  } else if (props.type === "danger") {
    bgClass = "bg-red-danger";
    colorClass = "text-white";
  } else if (props.type === "success") {
    bgClass = "bg-emerald-500";
    colorClass = "text-white";
  }

  let cursorClass = "";
  if (onClick) {
    cursorClass = "cursor-pointer";
  }

  const sizeClass = `text-${size}`;

  const marginBottom = props.marginBottom ?? "mb-2";

  return (
    <span
      className={`inline-block px-2 ${marginBottom} ${sizeClass} mr-2 ${bgClass} ${colorClass} rounded ${className} ${cursorClass}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </span>
  );
}
