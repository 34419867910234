// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import {
  ApiResponse,
  GraphQlQuery,
  GraphQlOneRequest,
  GraphQlRequest,
  ApiCall,
  AshDataError,
  NormalizedMutationResponse,
} from "types/api";
import {
  apiErrorResponse,
  decodeGqlErrorResponse,
  fetchAndLog,
  httpStatusCategory,
  gfGqlHeaders,
  wrapGqlResponse,
} from "./api-util";
import { FetchResult, MutationResult } from "@apollo/client";

export function stringifyFields(fields: string[]) {
  return fields.join(",\n");
}

export function stringifyObject(value: any): string {
  return "{" + stringifyArgs(value) + "}";
}

export function stringifyArray(value: any[]): string {
  return "[" + value.map((item) => stringifyArgValue(item)).join(",\n") + "]";
}

export async function graphqlSingleCall<T>(
  req: GraphQlOneRequest
): Promise<ApiResponse<T>> {
  const { call, query, opName, opType } = req;
  const { qName, decoder } = query;
  let wrappedQuery: string;
  if (query.encodedQuery) {
    wrappedQuery = query.encodedQuery;
  } else {
    const encodedQuery = buildGqlSingleQuery(query);
    wrappedQuery = wrapGqlQuery(opType, opName, encodedQuery, query.argTypes);
  }

  const response = await sendGraphqlQuery(
    call,
    opName,
    wrappedQuery,
    req.query.variables
  );
  let respData = await response.json();
  return wrapGqlResponse(response, respData, qName, decoder);
}

export async function graphqlMultiCall<T>(
  req: GraphQlRequest
): Promise<ApiResponse<T>> {
  const encodedQueries = req.queries.map(buildGqlSingleQuery).join("\n");
  const encodedQuery = wrapGqlQuery(
    req.opType,
    req.opName,
    encodedQueries,
    req.argTypes
  );

  const response = await sendGraphqlQuery(
    req.call,
    req.opName,
    encodedQuery,
    req.variables
  );
  const body = await response.json();

  const status = httpStatusCategory(response.status);
  if (status === "ok") {
    const errorResp = decodeGqlErrorResponse(response, body);
    if (errorResp) {
      return errorResp;
    }

    const result = req.queries.reduce(
      (tempBody, query) => {
        const resp = wrapGqlResponse(
          response,
          body,
          query.qName,
          query.decoder || req.decoder
        );

        if (resp.error) {
          return { ...tempBody, error: true };
        } else {
          return {
            ...tempBody,
            data: { ...tempBody.data, [query.qName as string]: resp.data },
          };
        }
      },
      { data: {}, error: false } as any
    );

    return result;
  } else {
    return apiErrorResponse(response, body);
  }
}

export async function sendGraphqlQuery(
  call: ApiCall,
  operationName: string,
  query: string,
  variables?: any
): Promise<Response> {
  const path = `/gql?op=${operationName}`;
  const endpointUrl = call.baseUrl + path;
  let bodyData = { query } as any;
  if (variables) {
    bodyData = { ...bodyData, variables };
  }

  return fetchAndLog(endpointUrl, {
    method: "POST",
    body: JSON.stringify(bodyData),
    headers: gfGqlHeaders(call),
  });
}

export function buildGqlSingleQuery(req: GraphQlQuery) {
  const { qName, args, argPlaceholders, argTypes, returnNames } = req;

  let argsString;
  if (argTypes) {
    argsString = encodeVariablePlaceholdersFromTypes(argTypes);
  } else if (argPlaceholders) {
    argsString = encodeVariablePlaceholders(argPlaceholders);
  } else if (args) {
    argsString = stringifyArgs(args);
  }

  const wrappedArgs = (argsString && `(${argsString})`) || "";
  const fieldsString = stringifyFields(returnNames as string[]);
  const fieldsBlock =
    returnNames && returnNames.length > 0 ? `{ ${fieldsString} }` : "";
  return `${qName}${wrappedArgs} ${fieldsBlock}`;
}

interface VariableDefs {
  [index: string]: string;
}

interface VariablePlaceholders {
  [index: string]: string;
}

export function encodeVariableDefs(defs: VariableDefs): string {
  return Object.keys(defs)
    .map((key) => {
      const def = defs[key];
      return encodeVariableDef(key, def);
    })
    .join(", ");
}

export function encodeVariablePlaceholders(defs: VariablePlaceholders): string {
  return Object.keys(defs)
    .map((key) => {
      const placeholder = defs[key];
      return `${key}: $${placeholder}`;
    })
    .join(", ");
}

export function encodeVariablePlaceholdersFromTypes(
  defs: VariableDefs
): string {
  return Object.keys(defs)
    .map((key) => {
      return `${key}: $${key}`;
    })
    .join(", ");
}

export function encodeVariableDef(key: string, type: string) {
  return `$${key}: ${type}`;
}

export function enclosedArgsString(args: any) {
  const argsString = stringifyArgs(args);
  return (argsString && `(${argsString})`) || "";
}

export function stringifyArgs(args: any): string | null {
  if (!args) {
    return null;
  }

  return Object.keys(args)
    .map((key) => `${key}: ${stringifyArgValue(args[key])}`)
    .join(",\n");
}

export function stringifyArgValue(value: any): string {
  if (Array.isArray(value)) {
    return stringifyArray(value);
  } else if (value === null) {
    return "null";
  } else if (typeof value === "object") {
    return stringifyObject(value);
  } else {
    value = value === undefined ? null : value;
    return JSON.stringify(value);
  }
}

export function wrapGqlQuery(
  callType: "query" | "mutation",
  name: string,
  query: string,
  argTypes?: { [index: string]: string }
) {
  let nameWithTypes = name;
  if (argTypes) {
    const inner = encodeVariableDefs(argTypes);
    const wrappedTypes = inner ? `(${inner})` : "";
    nameWithTypes = `${name}${wrappedTypes}`;
  }

  return `${callType} ${nameWithTypes} { ${query} }`;
}

export function mutationSuccessful(response: any) {
  const errors = mutationErrors(response);
  return errors.length === 0;
}

export function mutationErrors(mutationResult: any) {
  let errors: AshDataError[] = [];

  if (mutationResult.error2) {
    errors = mutationResult.errors;
  } else if (mutationResult.error) {
    errors = [mutationResult.error];
  }

  let errorKey = Object.keys(mutationResult.data || {}).find(
    (key) => !!mutationResult.data[key].errors
  );
  if (errorKey) {
    errors = [...errors, ...mutationResult.data[errorKey].errors];
  }

  return errors;
}

export function mutationSuccess(result: MutationResult | FetchResult) {
  const mutationResult = result as MutationResult;
  const errors = mutationErrors(mutationResult);

  if (typeof mutationResult.called === "boolean") {
    const { called, loading } = mutationResult;
    return called && !loading && errors.length === 0;
  }
  return errors.length === 0;
}

export function buildListQueryResult<T>(
  gqlResponse: any,
  queryName: string,
  opts?: { decodeResult: (v: any) => T }
): { data: T[]; errors: null } | { errors: AshDataError[] } {
  if (gqlResponse.data.errors) {
    return { errors: gqlResponse.data.errors };
  }
  const decodeResult = opts?.decodeResult || ((v: any) => v as T);
  const data = gqlResponse.data[queryName];
  return { data: data.map(decodeResult), errors: null };
}

export function buildPaginatedQueryResult<T>(
  gqlResponse: any,
  queryName: string,
  opts?: { decodeResult: (v: any) => T }
):
  | { results: T[]; errors: null; count: number; hasNextPage: boolean }
  | { errors: AshDataError[] } {
  if (gqlResponse.data.errors) {
    return { errors: gqlResponse.data.errors };
  }
  const paginationObject = gqlResponse.data[queryName];
  const decoder = opts?.decodeResult || ((v: any) => v as T);
  const results = paginationObject.results.map(decoder);
  return { ...paginationObject, results, errors: null };
}

// Decode a mutation result from an Apollo mutation response
export function buildMutationResult<T>(
  gqlResponse: any,
  mutationName: string,
  opts?: { decodeResult: (v: any) => T }
): NormalizedMutationResponse<T> {
  const errors = mutationErrors(gqlResponse);

  if (errors && errors.length > 0) {
    return {
      data: null,
      errors,
      hasError: true,
      metadata: {},
      serverError: null,
    };
  }

  const gqlData = gqlResponse?.data;
  const opResult = gqlData && gqlData[mutationName];
  const decodeData = opts?.decodeResult;
  const data = decodeData ? decodeData(opResult.result) : opResult.result;

  return {
    data,
    errors: null,
    hasError: false,
    metadata: opResult.metadata,
    serverError: null,
  };
}

export const ERROR_OBJECT = `
    errors {
      code
      fields
      message
      shortMessage
      vars
    }
`;
