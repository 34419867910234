// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { FieldEditing } from "types/fieldEditing";

interface Props {
  className?: string;
  fieldEditing: FieldEditing;
  label?: string | React.ReactNode;
  name: string;
}

export default function FieldEditButton(props: Props) {
  const { className, fieldEditing, label, name } = props;
  const { setCurrentFieldName } = fieldEditing;

  return (
    <button
      className={`a ${className || ""}`}
      onClick={() => setCurrentFieldName(name)}
    >
      {label || "edit"}
    </button>
  );
}
