interface Props {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function Tab(props: Props) {
  const { active, children, onClick } = props;
  const activeClass = active
    ? "bg-white cursor-default text-gray-darkest"
    : "hover:bg-white text-gray-darker hover:text-black";
  const className = `${activeClass} ${props.className} px-2 pb-1 rounded-tl rounded-tr focus:outline-none`;

  return (
    <button className={className} onClick={onClick} disabled={active}>
      {children}
    </button>
  );
}
