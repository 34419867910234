// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { Socket } from "lib/phoenix";
import phoenixTypes from "types/phoenix";

export function connectWebSocket(
  url: string,
  jwt: string,
  groupSlug: string
): phoenixTypes.Socket | null {
  if (!jwt) {
    throw new Error("No jwt is set");
  }

  if (typeof window === "undefined") return null;
  const socket = new Socket(url, {
    params: { jwt, group: groupSlug },
  }) as any;
  socket.connect();
  return socket;
}
