// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { UseFormReturn } from "react-hook-form";
import { FieldEditing } from "types/fieldEditing";
import { ColumnStyleFieldRow } from ".";
import EditButtonWithModal from "./EditButtonWithModal";
import SingleFieldForm from "./SingleFieldForm";

interface Props {
  autoFocus?: boolean;
  button?: React.ReactNode | undefined;
  buttonLabel?: string | undefined;
  children?: React.ReactNode;
  displayValue?:
    | string
    | React.ReactNode
    | ((value: any) => React.ReactNode)
    | null
    | undefined;
  errorMessages?: { [index: string]: string };
  fieldEditing: FieldEditing;
  initialValues?: { [index: string]: any };
  label?: string;
  name: string;
  registerProps?: any;
  renderInput?: (props: any, hookForm: UseFormReturn<any>) => React.ReactNode;
  value?: any;
}

// Editable FieldRow for column-based (non-dynamic) fields
export default function EditableFieldRow(props: Props) {
  const { autoFocus, button, buttonLabel, fieldEditing, name } = props;
  const label = props.label || props.name;
  let displayValue = props.displayValue || props.value;
  if (typeof displayValue === "function") {
    displayValue = displayValue(props.value);
  }

  function handleSubmit(arg: any) {
    return fieldEditing.handleSubmit && fieldEditing.handleSubmit(arg);
  }

  return (
    <ColumnStyleFieldRow
      label={label}
      fieldColumnProps={{ className: "overflow-auto" }}
      className="flex flex-wrap"
    >
      {displayValue}
      <div>
        <EditButtonWithModal
          button={button}
          name={name}
          label={buttonLabel}
          fieldEditing={fieldEditing}
        >
          {props.children || (
            <SingleFieldForm
              autoFocus={autoFocus}
              errorMessages={props.errorMessages}
              name={name}
              label={label}
              initialValue={props.value}
              initialValues={props.initialValues}
              onSubmit={handleSubmit}
              registerProps={props.registerProps}
              renderInput={props.renderInput}
            />
          )}
        </EditButtonWithModal>
      </div>
    </ColumnStyleFieldRow>
  );
}
