// Copyright © 2017 Moxley Data Systems - All Rights Reserved

export default function dig(value: any, path: any): any {
  if (!value || !path) return value;
  const match = path.match(/^(\w+)(?:\.(.*))?$/);
  if (match) {
    const segment = match[1];
    const nestedValue = value[segment];

    if (nestedValue !== undefined) {
      return dig(nestedValue, match[2]);
    }
    return undefined;
  }

  const match2 = path.match(/^\[(\d+)\](\.)?(.*)$/);
  if (!match2) return undefined;
  const index = parseInt(match2[1]);
  const nestedValue = value[index];
  return dig(nestedValue, match2[3]);
}
