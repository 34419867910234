// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { makeObservable, observable, runInAction } from "mobx";
import * as Sentry from "@sentry/browser";

import { OldApiError } from "lib/gf-api/api-util";
import {
  listContentItems2,
  listContentItemsFromS3,
} from "lib/gf-api/content-api";
import { ApiDataResponse, ApiResponse } from "types/api";
import {
  ContentItem,
  NavigationData,
  ContentItemExtended,
  ContentItemDoubleExtended,
} from "types/content";
import { GroupConfig } from "types/group";

interface Props {
  baseUrl?: string;
  groupConfig?: GroupConfig;
  jwt?: string | null | undefined;
  groupSlug?: string;
}

// Expiration in milliseconds
const CONTENT_EXPIRE = 3600 * 1000;

export default class ContentStore {
  baseUrl: string | null;
  navigation: NavigationData | null;
  loadingNavigation: Promise<NavigationData> | null;
  groupConfig: GroupConfig | null;
  pageId: string | null;
  pageLoadCallback: null | (() => void);
  jwt: string | null;
  navItems: ContentItem[] | null;
  textItems: ContentItem[] | null;
  loadingTextItems: Promise<ContentItem[]> | null;
  groupSlug: string | null;

  constructor(props: Props) {
    this.baseUrl = props.baseUrl || null;
    this.groupConfig = props.groupConfig || null;
    this.pageId = null;
    this.pageLoadCallback = null;
    this.navigation = null;
    this.navItems = null;
    this.loadingNavigation = null;
    this.jwt = props.jwt || null;
    this.textItems = null;
    this.loadingTextItems = null;
    this.groupSlug = props.groupSlug || null;
    makeObservable(this, {
      navItems: observable,
      navigation: observable,
      pageId: observable,
      textItems: observable,
    });
  }

  reloadNavigationData(props?: { useApi: boolean }): Promise<NavigationData> {
    this.loadingNavigation = null as Promise<NavigationData> | null;
    return this.loadNavigationData(props) as Promise<NavigationData>;
  }

  async listNavItems(props?: {
    useApi?: boolean;
  }): Promise<ApiResponse<ContentItem[]>> {
    if (!this.groupConfig) {
      throw new Error("No groupConfig found");
    }
    if (!this.baseUrl) {
      throw new Error("No baseUrl found");
    }
    if (!this.groupSlug) {
      throw new Error("No groupSlug found");
    }

    if (props?.useApi && this.jwt && this.baseUrl && this.groupSlug) {
      return listContentItems2(
        { baseUrl: this.baseUrl, groupSlug: this.groupSlug, jwt: this.jwt },
        { allNav: true }
      );
    }

    try {
      return await listContentItemsFromS3("nav", this.groupConfig);
    } catch (error) {
      Sentry.captureException(error);
      // We still get navigation items when developing on an airplane.
      if (error instanceof OldApiError) {
        return listContentItems2(
          { baseUrl: this.baseUrl, groupSlug: this.groupSlug, jwt: this.jwt },
          { allNav: true }
        );
      }
      throw error;
    }
  }

  loadNavigationData(props?: {
    force?: boolean;
    useApi?: boolean;
  }): Promise<NavigationData> | null {
    // Only load on client
    if (typeof window === "undefined") {
      return null;
    }

    if (this.loadingNavigation && !props?.force) {
      return this.loadingNavigation;
    }

    this.loadingNavigation = new Promise<NavigationData>((resolve) => {
      this.listNavItems(props).then((result) => {
        if (result.error) {
          const error = result.requestErrors[0];
          throw new Error(error.title);
        }

        const items = (result as ApiDataResponse<ContentItem[]>).data;

        let navLists = [] as ContentItemExtended[];
        let navMultiLists = [] as ContentItemDoubleExtended[];
        let links = [] as ContentItem[];

        items.forEach((item) => {
          if (item.plurality === "multi_list") {
            navMultiLists.push(item as ContentItemDoubleExtended);
          } else if (item.plurality === "list") {
            navLists.push(item as ContentItemExtended);
          } else if (item.plurality === "item") {
            links.push(item);
          }
        });

        let primaryNav = navLists.find(
          (n) => n.slug === "primary-nav"
        ) as ContentItemExtended;
        let secondaryNav = navMultiLists.find(
          (n) => n.slug === "secondary-nav"
        ) as ContentItemDoubleExtended;
        let sideNav = navMultiLists.find(
          (n) => n.slug === "side-nav"
        ) as ContentItemDoubleExtended;

        let children;
        if (primaryNav) {
          children = links
            .filter((l) => l.parentId === primaryNav.id)
            .sort(compareChildren) as ContentItem[];

          primaryNav = { ...primaryNav, children };
        }

        // Secondary nav
        if (secondaryNav) {
          children = navLists
            .filter((n) => n.parentId === secondaryNav?.id)
            .map((n) => {
              const children = links
                .filter((l) => l.parentId === n.id)
                .sort(compareChildren);
              return { ...n, children };
            })
            .sort(compareChildren);
          secondaryNav = {
            ...secondaryNav,
            children,
          } as ContentItemDoubleExtended;
        }

        // Side nav
        if (sideNav) {
          children = navLists
            .filter((n) => n.parentId === sideNav.id)
            .map((n) => {
              const children = links
                .filter((l) => l.parentId === n.id)
                .sort(compareChildren);
              return { ...n, children };
            })
            .sort(compareChildren);
          sideNav = { ...sideNav, children } as ContentItemDoubleExtended;
        }

        const navigation = {
          sideNav,
          secondaryNav,
          primaryNav,
        };

        runInAction(() => {
          this.navItems = items;
          this.navigation = navigation;
        });

        setTimeout(() => {
          this.loadNavigationData({ force: true });
        }, CONTENT_EXPIRE);

        resolve(navigation);
      });
    });

    return this.loadingNavigation;
  }

  registerCurrentPage(id: null | string, loadCallback: () => void) {
    runInAction(() => {
      this.pageId = id;
      this.pageLoadCallback = loadCallback;
    });
  }

  onPageUpdated(id: string) {
    setTimeout(() => {
      this.loadNavigationData({ force: true });
      this.getTextItems({ force: true });
    }, 200);
    if (!this.pageLoadCallback || !this.pageId) return null;
    if (this.pageId === id) {
      this.pageLoadCallback();
    }
  }

  getTextItems(props?: { force: boolean }): Promise<ContentItem[]> {
    if (!this.groupConfig) {
      throw new Error("No groupConfig found");
    }
    if (!this.baseUrl) {
      throw new Error("No baseUrl found");
    }
    if (!this.groupSlug) {
      throw new Error("No groupSlug found");
    }

    const force = props?.force;
    if (!force && this.loadingTextItems) {
      return this.loadingTextItems;
    }

    this.loadingTextItems = new Promise((resolve, reject) => {
      if (!this.groupConfig) {
        throw new Error("No groupConfig found");
      }
      if (!this.baseUrl) {
        throw new Error("No baseUrl found");
      }
      if (!this.groupSlug) {
        throw new Error("No groupSlug found");
      }

      listContentItemsFromS3("text", this.groupConfig)
        .then((result) => {
          if (result.error) {
            reject(result);
          } else {
            runInAction(() => {
              this.textItems = result.data;
              resolve(this.textItems);
            });
            setTimeout(() => {
              this.getTextItems({ force: true });
            }, CONTENT_EXPIRE);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          // We still get navigation items when developing on an airplane.
          if (error instanceof OldApiError) {
            if (!this.baseUrl) {
              throw new Error("No baseUrl found");
            }
            if (!this.groupSlug) {
              throw new Error("No groupSlug found");
            }
            return listContentItems2(
              {
                baseUrl: this.baseUrl,
                groupSlug: this.groupSlug,
                jwt: this.jwt,
              },
              { baseType: "text" }
            ).then((result) => {
              if (!result.error) {
                runInAction(() => {
                  this.textItems = result.data;
                  resolve(this.textItems);
                });
                setTimeout(() => {
                  this.getTextItems({ force: true });
                }, CONTENT_EXPIRE);
              }
            });
          } else {
            reject(error);
          }
        });
    });

    return this.loadingTextItems;
  }
}

function compareChildren(a: ContentItem, b: ContentItem) {
  return a.order <= b.order ? -1 : 1;
}
