import Head from "next/head";
import { observer } from "mobx-react-lite";

import { encodeUriComponentLight } from "lib/string";
import { GroupConfig, WebSiteFont } from "types/group";

interface Props {
  groupConfig: GroupConfig;
}

function GoogleFonts({ groupConfig }: Props) {
  const website = groupConfig.webSite || groupConfig.website;
  const fonts = website?.config.fonts;

  const googleArgs = (fonts || [])
    .filter((f: WebSiteFont) => f.type === "google" && f.vendorString)
    .map(
      (font: WebSiteFont) =>
        "family=" + encodeUriComponentLight(font.vendorString || "")
    );

  const googleQuery = googleArgs.length > 0 ? "?" + googleArgs.join("&") : "";
  return (
    <Head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href={"https://fonts.googleapis.com/css2" + googleQuery}
        rel="stylesheet"
      />
    </Head>
  );
}

export default observer(GoogleFonts);
