import { observer } from "mobx-react-lite";

import { formatDate } from "lib/date";
import { useGroupConfig } from "lib/gf-app-context";

interface Props {
  className?: string;
  parentTag?: string;
}

const GroupFooterCopyright = (props: Props) => {
  const { className, parentTag } = props;
  const group = useGroupConfig();

  return (
    <div className={`${parentTag} GroupCopyright ${className || ""}`}>
      &copy; {formatDate(new Date(), "yyyy")} {group.name}
    </div>
  );
};

export default observer(GroupFooterCopyright);
