import { ContentItem } from "types/content";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import StatusIcon from "./StatusIcon";
import TextSubTypeHeading from "./TextSubTypeHeading";

interface Props {
  contentItem: ContentItem;
}

export default function TextItemResultItem(props: Props) {
  const { contentItem } = props;

  return (
    <div title={`ID: ${contentItem.id}`} className="flex gap-1 items-center">
      <ContentItemTypeIcon contentItem={contentItem} />
      <StatusIcon contentItem={contentItem} />
      <div className="ml-2 flex gap-1">
        <div>
          <TextSubTypeHeading
            plurality={contentItem.plurality}
            subType={contentItem.subType}
          />
        </div>
        <div>{contentItem.title}</div>
        <div className="text-gray-dark">{contentItem.internalName}</div>
      </div>
    </div>
  );
}
