import GreenCircleIcon from "components/icon/GreenCircleIcon";
import YellowCircleIcon from "components/icon/YellowCircleIcon";
import { ContentItem } from "types/content";

interface Props {
  contentItem: ContentItem;
}

const iconClass = "fill-current text-gray-dark";
const iconStyle = { width: "18px", height: "18px" };

export default function StatusIcon(props: Props) {
  const { contentItem } = props;
  const Cmp =
    contentItem.status === "published" ? GreenCircleIcon : YellowCircleIcon;

  return (
    <div title={contentItem.status} style={{ cursor: "help" }}>
      <Cmp className={iconClass} style={iconStyle} />
    </div>
  );
}
