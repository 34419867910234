/*!
 * A pop-up modal that automatically opens on the home page when the member is logged in.
 */

import { observer } from "mobx-react-lite";
import Link from "next/link";
import { ReactNode } from "react";

import useHomeWelcomeBack from "hooks/useHomeWelcomeBack";
import { memberCanSeeAdmin } from "lib/auth";
import ReactModal from "react-modal";
import { CloseButton } from "components/ui";
import { buildClassName } from "lib/string";
import clsx from "clsx";

interface Props {
  className?: string;
  parentTag?: string;
}

const WelcomeBackPopUp = (props: Props) => {
  const { className, parentTag } = props;
  const { member, modalOpen, onClose, setModalOpen } = useHomeWelcomeBack();
  if (!member) return null;

  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(128, 128, 128, 0.75)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      maxWidth: "600px",
      maxHeight: "98%",
      width: "100%",
    },
  };

  return (
    <ReactModal
      isOpen={modalOpen}
      className="WelcomeBackPopUp gf-customer gf-area-marketing"
      onRequestClose={() => setModalOpen(false)}
      style={modalStyle}
    >
      <div
        className={buildClassName(parentTag, "BackgroundImage", className)}
      />
      <div className={buildClassName(parentTag, "BackgroundOpacity")} />
      <div className="Body">
        <div className="CloseButton flex justify-end float-right transition">
          <CloseButton
            onClick={onClose}
            textClass=" "
            svgClass="fill-current"
          />
        </div>

        <h2 className="h2">Welcome back {member?.account.name}!</h2>
        <p className="p">Where would you like to go?</p>
        <div className="ButtonsWrapper flex flex-col items-center">
          <div className="Buttons flex flex-col items-stretch gap-2">
            <LinkButton href="/members/home">Member Home</LinkButton>
            <LinkButton href="/events">Events</LinkButton>
            <LinkButton href="/members/pages/resources">Resources</LinkButton>
            <LinkButton href="/members/messages">Messages</LinkButton>
            <LinkButton href="/members/account">Account</LinkButton>
            {memberCanSeeAdmin(member.account) && (
              <LinkButton href="/_admin">Admin</LinkButton>
            )}
            <LinkButton href="/logout">Logout</LinkButton>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default observer(WelcomeBackPopUp);

const LinkButton = ({
  href,
  children,
  className,
}: {
  href: string;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <Link href={href} className={clsx("Button transition", className)}>
      {children}
    </Link>
  );
};
