// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import Style from "lib/Style";
import RequiredFieldAsterisk from "./RequiredFieldAsterisk";

interface Props {
  children: React.ReactNode;
  className?: string;
  htmlFor?: string;
  id?: string;
  marginBottomClass?: string;
  required?: boolean;
  style?: any;
  title?: string;
}

export default function RowLabel(props: Props) {
  const { children, htmlFor, id, required, style, title } = props;
  const marginBottomClass = props.marginBottomClass ?? "mb-2";
  const className = `RowLabel block ${
    props.className || ""
  } ${marginBottomClass} ${Style.labelText}`;

  return (
    <label
      htmlFor={htmlFor}
      id={id}
      className={className}
      title={title}
      style={style}
    >
      {children}
      {required ? <RequiredFieldAsterisk /> : null}
    </label>
  );
}
