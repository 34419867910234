// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { SyntheticEvent } from "react";
import FieldLabel from "./FieldLabel";
import RequiredFieldAsterisk from "./RequiredFieldAsterisk";
import clsx from "clsx";
import { ADMIN_STYLE } from "lib/Style";
import { useThemeInfo } from "lib/theme";

interface Props {
  children: React.ReactNode;
  className?: string;
  columnStyle?: boolean;
  label?: string | React.ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  required?: boolean;
}

export default function FormRow(props: Props) {
  const themeInfo = useThemeInfo();
  const { children, className, label, onClick, required } = props;
  const { themeArea } = themeInfo;
  let themeAreaClass = "";
  if (themeArea === "admin" && !props.columnStyle) {
    themeAreaClass = ADMIN_STYLE.labelAndField;
  }

  return (
    <div className={clsx(themeAreaClass, className)} onClick={onClick}>
      {label && (
        <FieldLabel themeArea={themeArea}>
          {label}
          {required && <RequiredFieldAsterisk />}
        </FieldLabel>
      )}
      {children}
    </div>
  );
}
