import { EnvelopeIcon } from "@heroicons/react/24/outline";
import AshMutationErrors from "components/alerts/AshMutationErrors";
import AuthCodeForm from "components/authCode/AuthCodeForm";
import Button from "components/Button";
import { UseFormReturn } from "react-hook-form";
import { MutationInfo } from "types/ash";
import { TinySelfMember } from "types/member";

interface Props {
  codeSent: boolean;
  form: UseFormReturn<any>;
  onAuthCodeSelection: () => void;
  onAccessToken: (token: string, account: TinySelfMember) => void;
  signupWithAuthState: MutationInfo;
}

export default function AuthCodeFlow(props: Props) {
  const {
    codeSent,
    form,
    onAuthCodeSelection,
    onAccessToken,
    signupWithAuthState,
  } = props;
  const { watch } = form;

  return (
    <div className="flex flex-col items-center">
      <AshMutationErrors mutationInfo={signupWithAuthState} />
      {!codeSent && (
        <Button onClick={onAuthCodeSelection}>
          <EnvelopeIcon className="w-4 h-4" />
          Send login code
        </Button>
      )}
      {codeSent && (
        <AuthCodeForm
          email={watch("email")}
          onAccessToken={onAccessToken}
          skipLogin
        />
      )}
    </div>
  );
}
