import clsx from "clsx";
import { TextInput } from ".";

interface Props {
  className?: string;
  disabled?: boolean;
  min?: number;
  register?: any;
  step?: number;
}

export default function MoneyInput(props: Props) {
  return (
    <div className={clsx("relative", props.className)}>
      <TextInput
        type="number"
        register={props.register}
        min={props.min}
        step={props.step}
        className="w-full pl-10"
        style={{ paddingLeft: "2rem" }}
        disabled={props.disabled}
      />
      <div className="flex flex-col justify-center absolute top-0 h-full px-4 font-medium">
        <div>$</div>
      </div>
    </div>
  );
}
