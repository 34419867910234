import Flash from "components/admin/ui/Flash";
import { MutationInfo } from "types/ash";

interface Props<T> {
  mutationInfo: MutationInfo<T>;
  message: string;
}

export default function AshMutationSuccess<T>({
  message,
  mutationInfo,
}: Props<T>) {
  if (!mutationInfo) return null;
  const { serverError, loading, errors, data } = mutationInfo;
  if (!data || loading || serverError || (errors && errors.length > 0)) {
    return null;
  }
  return <Flash type="info">{message}</Flash>;
}
