import React, { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

export default function ServerError404() {
  useEffect(() => {
    Sentry.captureMessage("404 page");
  }, []);

  return (
    <section>
      <h1 className="h1">404 - Page Not Found</h1>
    </section>
  );
}
