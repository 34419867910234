// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItemSettings } from "types/content";
import { MemberStatus } from "types/member";
import { NavItem } from "types/nav";

interface SimpleItem {
  settings?: ContentItemSettings;
  uri: string | null;
}

export function filterItemsForLoggedInStatus(
  items: NavItem[],
  memberStatus: MemberStatus | undefined,
  memberRoles: string[] | undefined
): NavItem[] {
  return items.filter(
    (item) => !item.settings || shouldShow(item, memberStatus, memberRoles)
  );
}

export function shouldShow(
  { settings, uri }: SimpleItem,
  memberStatus: MemberStatus | undefined,
  memberRoles?: string[]
) {
  const {
    isLoggedInVisible,
    isLoggedOutVisible,
    role: itemRole,
  } = settings || {};
  memberRoles = memberRoles && [...memberRoles];

  if (
    itemRole &&
    (!memberRoles ||
      !(memberRoles.includes(itemRole) || memberRoles.includes("super")))
  ) {
    return false;
  }

  const loggedIn = memberStatus === "ACTIVE";

  const inactiveVisible = () =>
    !uri ||
    uri.startsWith("/members/dues") ||
    uri.startsWith("/members/subscriptions") ||
    uri.startsWith("/members/account") ||
    uri.startsWith("/members/profile");

  const nonMemberVisible = () =>
    !uri ||
    uri.startsWith("/members/subscriptions") ||
    uri.startsWith("/members/profile") ||
    uri.startsWith("/members/account");

  const bannedMemberVisible = () => !uri || uri.startsWith("/members/account");

  if (uri && uri.startsWith("/members")) {
    if (memberStatus === "ACTIVE") {
      return isLoggedInVisible;
    } else if (memberStatus === "INACTIVE" && inactiveVisible()) {
      return true;
    } else if (memberStatus === "NON_MEMBER" && nonMemberVisible()) {
      return true;
    } else if (memberStatus === "BANNED" && bannedMemberVisible()) {
      return true;
    }
  } else if (loggedIn && isLoggedInVisible) {
    return true;
  } else if (!loggedIn && isLoggedOutVisible) {
    return true;
  }

  return false;
}

// Apply hide & show logic and active & inactive logic on the given nav items
export function transformNavItems(
  links: NavItem[],
  memberStatus: MemberStatus | undefined,
  roles: string[] | undefined,
  currentPath: string
) {
  const filteredLinks = filterItemsForLoggedInStatus(
    links,
    memberStatus,
    roles
  );

  return markActive(filteredLinks, currentPath);
}

function markActive(links: NavItem[], currentPath: string): NavItem[] {
  let itemIndex = links.findIndex((item) => item.uri === currentPath);
  if (itemIndex >= 0) {
    return links.map((link, index) => {
      const active = index === itemIndex;
      return { ...link, active };
    });
  } else {
    return markActiveFromInexactMatch(links, currentPath);
  }
}

function markActiveFromInexactMatch(
  links: NavItem[],
  currentPath: string
): NavItem[] {
  let matches = links.filter((link) => currentPath.startsWith(link.uri));

  if (matches.length === 0) {
    return links;
  } else {
    // Find longest match
    const longest = matches.sort(
      (a: NavItem, b: NavItem) => b.uri.length - a.uri.length
    )[0];

    return links.map((link) => {
      const active = link.uri === longest.uri;
      return { ...link, active };
    });
  }
}
