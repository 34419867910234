// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import Style from "lib/Style";
import React from "react";

type Status = "normal" | "active" | undefined;

interface Props {
  bgColor?: string;
  bgHoverColor?: string;
  borderColor?: string;
  borderWidth?: string;
  children?: React.ReactNode;
  className?: string;
  colorLevel?: "secondary";
  disabled?: boolean;
  display?: string;
  id?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  onMouseDown?: (e: React.SyntheticEvent) => void;
  shape?: string;
  size?: "small" | "medium";
  status?: Status;
  style?: any;
  textColor?: string;
  type?: "button" | "reset" | "submit";
}

export default function Button(origProps: Props) {
  let {
    bgColor: origBgColor,
    bgHoverColor,
    borderColor: origBorderColor,
    borderWidth,
    colorLevel,
    display,
    status,
    className,
    shape,
    size,
    style,
    textColor,
    type,
    ...props
  } = origProps;
  textColor = textColor || "text-white";
  let bgColor = origBgColor || "bg-spp-violet";
  let borderColor = origBorderColor || "border-spp-violet";
  let bgHover = bgHoverColor || origBgColor;
  let textSize = "";
  let padding = "px-3";
  if (size === "small") {
    textSize = "text-sm";
    padding = "py-0 px-2";
  }

  if (colorLevel === "secondary") {
    bgColor = origBgColor || "bg-gray";
    borderColor =
      origBorderColor || "border-gray hover:border-spp-violet-lighter";
    textColor = "text-black hover:text-white";
    bgHover = origBgColor ? "" : "hover:bg-spp-violet-lighter";
  } else if (origProps.disabled) {
    bgColor = origBgColor || "bg-secondary-300 opacity-50";
    borderColor = origBorderColor || "border-gray";
    textColor = "text-white";
    bgHover = "";
  } else if (status === "active") {
    bgColor = origBgColor || "bg-spp-violet-lightest";
    borderColor = origBorderColor || "border-spp-violet-lightest";
    bgHover = origBgColor ? "" : "hover:bg-spp-violet-lighter";
  }

  borderWidth = borderWidth || "border-2";

  let bgClass = clsx(bgColor, borderWidth, borderColor, bgHover, textColor);
  shape = shape || Style.buttonShape;

  className = clsx(
    "Button",
    display,
    shape,
    bgClass,
    textSize,
    padding,
    className
  );
  type = type || "button";

  return (
    <button {...props} type={type} className={className} style={style}>
      {props.children}
    </button>
  );
}
