import { observer } from "mobx-react-lite";
import SimpleModal from "components/SimpleModal";
import { useAlertStore } from "lib/gf-app-context";
import Button from "components/Button";

// Displays an alert modal that can be called from anywhere.
// Use the AlertStore to control it.
function Alert() {
  const alertStore = useAlertStore();

  function onClose() {
    if (alertStore.modalProps.onClose) {
      alertStore.modalProps.onClose();
    }
    alertStore.close();
  }

  return (
    <SimpleModal
      isOpen={alertStore.isOpen}
      {...alertStore.modalProps}
      onClose={onClose}
    >
      <div className="flex flex-col gap-6">
        <div className="text-lg font-bold text-center">Alert</div>
        <div className="text-lg">{alertStore.children}</div>
        <div className="flex justify-center">
          <Button onClick={onClose}>Okay</Button>
        </div>
      </div>
    </SimpleModal>
  );
}

export default observer(Alert);
