import { Tab, TabContainer } from "components/tab";
import { TabData } from "types/ui";

interface Props<Screen> {
  className?: string;
  onChange?: (screen: Screen) => void;
  screen: Screen;
  tabs: TabData<Screen>[];
}

export default function Tabs<Screen>(props: Props<Screen>) {
  const { className, onChange, screen, tabs } = props;

  return (
    <TabContainer className={className}>
      {tabs.map((tab) => (
        <Tab
          key={tab.screen as any}
          active={tab.screen === screen}
          onClick={() => onChange && onChange(tab.screen)}
        >
          {tab.content}
        </Tab>
      ))}
    </TabContainer>
  );
}
