// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItem } from "types/content";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import StatusIcon from "./StatusIcon";

interface Props {
  navItem: ContentItem;
}

export default function NavItemResultItem(props: Props) {
  const { navItem } = props;
  return (
    <div className="flex justify-between gap-1 items-center">
      <ContentItemTypeIcon contentItem={navItem} />
      <StatusIcon contentItem={navItem} />
      <div className="flex-grow flex gap-2 shitespace-no-wrap whitespace-nowrap truncate">
        <div>{navItem.label}</div>
        <div className="text-gray-dark">{navItem.internalName}</div>
      </div>
    </div>
  );
}
