import { MutationInfo } from "types/ash";
import AshMutationErrors from "./AshMutationErrors";
import AshMutationSuccess from "./AshMutationSuccess";

interface Props<T> {
  successMessage: string;
  mutationInfo: MutationInfo<T>;
}

export default function AshMutationResult<T = Record<string, any>>(
  props: Props<T>
) {
  return (
    <>
      <AshMutationErrors mutationInfo={props.mutationInfo} />
      <AshMutationSuccess
        mutationInfo={props.mutationInfo}
        message={props.successMessage}
      />
    </>
  );
}
