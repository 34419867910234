// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { makeAutoObservable, runInAction } from "mobx";

import { getContentItem } from "lib/gf-api/content-api";
import { ApiCall, ApiResponse } from "types/api";
import {
  EditStatus,
  ContentItem,
  ContentBaseType,
  ContentItemExtended,
  ContentBaseTypeEditMode,
  ContentItemParams,
  NavigationState,
} from "types/content";

interface Props {
  baseUrl: string;
  jwt: string;
  groupSlug: string;
}

export default class ContentEditStore {
  baseUrl: string;
  jwt: string;
  loadingRootNavLists: Promise<ContentItem[] | null> | null;
  navStack: NavigationState[] = [];
  groupSlug: string;
  status: EditStatus;

  constructor(props: Props) {
    this.baseUrl = props.baseUrl;
    this.jwt = props.jwt;
    this.loadingRootNavLists = null;
    this.groupSlug = props.groupSlug;
    this.status = "inactive";
    makeAutoObservable(this);
  }

  setStatus(status: EditStatus) {
    runInAction(() => {
      this.status = status;
    });
  }

  // TODO Why does this method exist? Why not call `getContentItem()` directly?
  getContentItem(idArg: string): Promise<ApiResponse<ContentItemExtended>> {
    const id = idArg;
    if (!id) {
      throw new Error("No idArg or contentId set");
    }

    return new Promise((resolve) => {
      const apiCall: ApiCall = {
        baseUrl: this.baseUrl,
        groupSlug: this.groupSlug,
        jwt: this.jwt,
      };
      getContentItem(apiCall, id).then((result) => {
        if (result.error) {
          resolve(result);
        } else {
          runInAction(() => {
            resolve(result);
          });
        }
      });
    });
  }

  onEditingClose() {
    setTimeout(() => {
      this.clearEditing();
    }, 200);
  }

  clearEditing() {
    runInAction(() => {
      this.navStack = [];
      this.status = "inactive";
    });
  }

  edit(
    initialParams: Partial<ContentItemParams>,
    baseTypeEditMode?: ContentBaseTypeEditMode
  ) {
    runInAction(() => {
      this.setStatus("modalOpen");
      if (!baseTypeEditMode) {
        if (["list", "multi_list"].includes(initialParams.plurality || "")) {
          baseTypeEditMode = "list";
        }
      }
      this.pushStack({
        baseType: initialParams.baseType || "text",
        baseTypeEditMode,
        contentId: initialParams?.id,
        initialParams,
      });
    });
  }

  openModal() {
    runInAction(() => {
      this.status = "modalOpen";
      this.pushStack({
        baseTypeEditMode: "menu",
      });
    });
  }

  back() {
    this.popNavStack();
  }

  showSearch(baseType: ContentBaseType) {
    runInAction(() => {
      this.status = "modalOpen";
      const item: NavigationState = {
        baseType,
        baseTypeEditMode: "search",
      };
      this.navStack.push(item);
    });
  }

  currentNavItem(): NavigationState | undefined {
    return this.navStack[this.navStack.length - 1];
  }

  pushStack(item: NavigationState) {
    runInAction(() => {
      this.status = "modalOpen";
      this.navStack.push(item);
    });
  }

  popNavStack(): NavigationState | undefined {
    if (this.navStack.length === 0) {
      return undefined;
    } else {
      let item = this.currentNavItem();
      runInAction(() => {
        this.navStack.pop();
      });
      return item;
    }
  }

  updateCurrentNavItem(item: NavigationState) {
    if (this.navStack.length === 0) return;
    runInAction(() => {
      this.navStack[this.navStack.length - 1] = item;
    });
  }
}
