import { buildClassName } from "lib/string";
import NextLink from "next/link";
import { ReactNode } from "react";

interface Props {
  className?: string;
  href?: string;
  label?: string | ReactNode;
  parentTag?: string;
  style?: any;
}

export default function Link(props1: Props) {
  let { className, href, label, parentTag, style } = props1 as any;
  className = buildClassName(parentTag, className, "Link");

  return (
    <NextLink href={href || "/"} className={className} style={style}>
      {label || "LINK"}
    </NextLink>
  );
}
