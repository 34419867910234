import { makeObservable, observable, runInAction } from "mobx";

export default class StripeStore {
  _returnedFromStripe: boolean;

  constructor() {
    this._returnedFromStripe = false;

    makeObservable(this, {
      _returnedFromStripe: observable,
    });
  }

  get returnedFromStripe() {
    return this._returnedFromStripe;
  }

  set returnedFromStripe(value: boolean) {
    runInAction(() => {
      this._returnedFromStripe = value;
    });
  }
}
