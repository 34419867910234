// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ApiErrors from "components/ApiErrors";
import { UsingMpForm } from "types/fieldEditing";
import { SubmitButtonRow } from ".";

interface BasicFormProps {
  children: React.ReactNode;
  usingHookForm: UsingMpForm;
}

export default function BasicForm(props: BasicFormProps) {
  const { children, usingHookForm } = props;
  const { handleSubmit, hookForm, saveError, saving } = usingHookForm;
  const isDirty = hookForm.formState.isDirty;

  return (
    <form onSubmit={handleSubmit}>
      {children}
      <ApiErrors error={saveError} />
      <SubmitButtonRow disabled={!isDirty} saving={saving} />
    </form>
  );
}
