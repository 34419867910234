// Copyright © 2017 Moxley Data Systems - All Rights Reserved
interface Props {
  className?: string;
  style?: any;
}

export default function EditPropertiesIcon(props: Props) {
  const { className, style } = props;
  const otherProps = {} as any;

  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      className={className}
      style={style}
      title="Edit properties"
      {...otherProps}
    >
      <path d="M 5 3 C 3.895 3 3 3.895 3 5 L 3 19 C 3 20.105 3.895 21 5 21 L 16.171875 21 L 14.171875 19 L 5 19 L 5 9 L 19 9 L 18.998047 14.171875 L 21 16.171875 L 21 5 C 21 3.895 20.105 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 7 L 5 7 L 5 5 z M 7 11 L 7 13 L 9 13 L 9 11 L 7 11 z M 11 11 L 11 13 L 17 13 L 17 11 L 11 11 z M 7 15 L 7 17 L 9 17 L 9 15 L 7 15 z M 11 15 L 11 17 L 13 17 L 13 15 L 11 15 z M 15 15 L 15 17 L 20.146484 22.146484 L 22.146484 20.146484 L 17 15 L 15 15 z M 22.853516 20.853516 L 20.853516 22.853516 L 21.853516 23.853516 C 22.048516 24.048516 22.365547 24.048516 22.560547 23.853516 L 23.853516 22.560547 C 24.048516 22.364547 24.048516 22.048516 23.853516 21.853516 L 22.853516 20.853516 z" />
    </svg>
  );
}
