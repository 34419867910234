export const DEFAULT_LOGGED_OUT_PRIMARY_NAV_ITEMS = [
  { text: "About Us", url: "/about" },
  { text: "Public Events", url: "/events/public" },
  { text: "Resources", url: "/resources" },
  { text: "Join Us", url: "/join" },
];

export const DEFAULT_LOGGED_IN_PRIMARY_NAV_ITEMS = [
  { text: "Member Home", url: "/members/home" },
  { text: "Messages", url: "/members/messages" },
  { text: "Events", url: "/events" },
  { text: "Members", url: "/members" },
  { text: "Resources", url: "/members/pages/resources" },
];
