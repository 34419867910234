// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { Fragment } from "react";
import ButtonGroupItem from "types/ButtonGroupItem";
import CheckboxWithLabel from "./CheckBoxWithLabel";
import FieldOptionLayout from "./FieldOptionLayout";

interface Props {
  afterEachOption?: (value: any) => React.ReactNode;
  disabled?: boolean;
  name?: string;
  other?: { index: number; content: React.ReactNode };
  options: ButtonGroupItem[];
  register?: { [index: string]: any };
}

export default function CheckBoxGroup(props: Props) {
  const { afterEachOption, disabled, other, options, register } = props;
  if (!options) throw new Error("No options set in fieldProps");
  let name: string | undefined;
  let addProps = {};
  if (register) {
    name = register.name;
    addProps = { ...addProps, register };
  } else if (props.name) {
    name = props.name;
    addProps = { ...addProps, name };
  }

  function hiddenField() {
    if (options.length !== 1) return null;
    const { register, ...otherProps } = addProps as any;
    return (
      <input type="checkbox" className="hidden" {...register} {...otherProps} />
    );
  }

  return (
    <>
      {options.map((o, i) => (
        <Fragment key={`${o.value}-${i}`}>
          <CheckboxWithLabel
            disabled={disabled}
            {...addProps}
            required={o.required}
            value={o.value}
            label={o.label}
          />
          {afterEachOption && afterEachOption(o.value)}
          {other && i === other.index && (
            <FieldOptionLayout label={other.content} className="my-2" />
          )}
        </Fragment>
      ))}
      {hiddenField()}
    </>
  );
}
