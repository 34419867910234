import { makeAutoObservable, runInAction } from "mobx";

class LoginModalStore {
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  open() {
    runInAction(() => {
      this.isOpen = true;
    });
  }

  close() {
    runInAction(() => {
      this.isOpen = false;
    });
  }
}

export default LoginModalStore;
