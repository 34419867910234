import { parseDate } from "lib/gf-api/apiDecode";
import {
  EventDetail,
  EventSubmission,
  Participant,
  Participation,
} from "types/event";
import { AdminEvent } from "types/event-new";
import { MemberItem } from "types/member";
import { Photo } from "types/photo";

/**
 * Parse the data to get a Participant
 *
 * @param p
 * @returns Participant
 */
export function parseParticipant(p: any): Participant {
  return {
    ...p,
    createdAt: new Date(p.createdAt),
  };
}

/**
 * Parse the data to get a Participation
 * @param p
 * @returns
 */
export function parseParticipation(p: any): Participation {
  return {
    ...p,
    createdAt: p && parseDate(p.createdAt),
  };
}

/**
 * Parse the data to get an event submission
 *
 * @param e
 * @returns EventSubmission
 */
export function parseEventSubmission(e: any): EventSubmission {
  let startAt = new Date();
  let endAt = new Date();

  if (e.startAt) {
    startAt = new Date(e.startAt);
  }

  if (e.endAt) {
    endAt = new Date(e.endAt);
  }
  return {
    ...e,
    startAt,
    endAt,
  } as EventSubmission;
}

export function decodeAdminEvent(data: any): AdminEvent {
  const { announcedAt, startAt, endAt, publishedAt, submittedAt, updatedAt } =
    data;
  return {
    ...data,
    announcedAt: parseDate(announcedAt),
    startAt: parseDate(startAt),
    endAt: parseDate(endAt),
    publishedAt: parseDate(publishedAt),
    submittedAt: parseDate(submittedAt),
    updatedAt: parseDate(updatedAt),
  };
}

export function decodeEventDetail(e: any): EventDetail {
  return {
    ...e,
    startAt: parseDate(e.startAt),
    endAt: parseDate(e.endAt),
    publishedAt: parseDate(e.publishedAt),
    participants: (e.participants || []).map(parseParticipant),
    participation: parseParticipation(e.participation),
  } as EventDetail;
}

/**
 * Parse the data to get an Attendee
 *
 * @param data
 * @returns Attendee
 */
export function parseAttendee(data: any) {
  return {
    ...data,
    createdAt: parseDate(data.createdAt),
    member: data.member && parseAttendeeMember(data.member),
  };
}

/**
 * Parse the data to get an attendee member
 * @param m
 * @returns MemberItem
 */
function parseAttendeeMember(m: any): MemberItem {
  return {
    ...m,
    joinedGroupOn: new Date(m.joinedGroupOn),
    photo: parseMemberPhoto(m.photo),
  } as MemberItem;
}

/**
 * Parse the data to get a member photo
 *
 * @param p
 * @returns Photo
 */
function parseMemberPhoto(p: any): Photo | null {
  if (!p) {
    return null;
  } else {
    const { regularPath, thumbPath, ...sourcePhoto } = p;

    return {
      ...sourcePhoto,
      url: p.url || regularPath,
      thumbUrl: p.thumbUrl || thumbPath,
    };
  }
}
