import React, { useState } from "react";
import clsx from "clsx";
import { CloseButton } from "components/ui";

interface Props {
  children: React.ReactNode;
  noClose?: boolean;
  onClose?: () => void;
  type?: "info" | "warning";
  className?: string;
  textClass?: string;
}

const Flash = function (props: Props) {
  const { children, noClose } = props;
  const bg = props.type === "warning" ? "bg-warning" : "bg-new-green-500";
  const typeTextClass = props.type === "warning" ? "text-black" : "text-white";
  const textClass = props.textClass || typeTextClass;
  let closeTextClass = clsx(`text-slate-200 hover:text-white`);
  if (props.type === "warning") {
    closeTextClass = clsx(`text-slate-500 hover:text-black`);
  }
  const c1 = `${bg} rounded flex justify-between items-center px-2 py-1 ${textClass}`;
  const className = `${c1} ${props.className}`;
  const [visible, setVisible] = useState<boolean>(true);

  function onClose() {
    if (props.onClose) {
      props.onClose();
    }

    setVisible(false);
  }

  if (!visible) return null;

  return (
    <div className={className}>
      {children}
      {noClose ? null : (
        <div className={clsx("flex transition justify-end", closeTextClass)}>
          <CloseButton
            onClick={onClose}
            textClass=" "
            svgClass="fill-current"
          />
        </div>
      )}
    </div>
  );
};

export default Flash;
