// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}

export default function DeleteIcon(props: Props) {
  const { className, style } = props;
  const otherProps = {} as any;

  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50px"
      height="50px"
      className={className}
      style={style}
      title="Delete"
      {...otherProps}
    >
      <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
    </svg>
  );
}
