// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { SyntheticEvent } from "react";

interface Props {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (e: SyntheticEvent) => void;
  options?: { value: string | number; label: string | React.ReactNode }[];
  register?: any;
  style?: any;
  value?: string;
}

export default function Select(props: Props) {
  const { disabled, children } = props;
  const className = `border border-gray rounded ${props.className || ""}`;
  const style = { padding: "0.5em", ...(props.style || {}) };
  const register = props.register || {};
  let otherProps = {};
  if (props.onChange) {
    otherProps = { onChange: props.onChange };
  }
  const options = props.options || [];

  return (
    <select
      style={style}
      className={className}
      disabled={disabled}
      {...register}
      {...otherProps}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
      {children}
    </select>
  );
}
