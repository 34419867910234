/*!
 * High-level functions for WebComponents.
 *
 * Converts data to React components.
 */

import {
  Button,
  ContentSnippet,
  GroupCopyright,
  GroupFlowTag,
  Link,
  MarketingCards,
  Navigation,
  PageContent,
  PageQuote,
  PrimaryNavigation,
  SecondaryNavigation,
  WelcomeBackPopUp,
} from "./webComponent/presets";

export const WebComponentPresets = [
  { name: "Button", component: Button },
  { name: "ContentSnippet", component: ContentSnippet },
  { name: "GroupCopyright", component: GroupCopyright },
  { name: "GroupFlowTag", component: GroupFlowTag },
  { name: "Link", component: Link },
  { name: "MarketingCards", component: MarketingCards },
  { name: "Navigation", component: Navigation },
  { name: "PageContent", component: PageContent },
  { name: "PageQuote", component: PageQuote },
  { name: "PrimaryNavigation", component: PrimaryNavigation },
  { name: "SecondaryNavigation", component: SecondaryNavigation },
  { name: "WelcomeBackPopUp", component: WelcomeBackPopUp },
];

export function buildSiteContext() {
  const presets = WebComponentPresets.reduce(
    (acc, preset) => ({
      ...acc,
      [preset.name]: preset.component,
    }),
    {}
  );

  return { presets };
}
