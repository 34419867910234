// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { Fragment } from "react";
import FieldOptionLayout from "./FieldOptionLayout";
import RadioWithLabel, { RadioProps } from "./RadioWithLabel";

interface Props {
  afterEachOption?: (value: any) => React.ReactNode;
  currentValue?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  itemClassName?: string;
  name?: string;
  onClick?: (value: string) => void;
  onChange?: (value: string) => void;
  other?: { index: number; content: React.ReactNode };
  idPrefix?: string;
  radios: RadioProps[];
  register?: any;
}

export default function RadioGroup(props: Props) {
  let {
    afterEachOption,
    currentValue,
    idPrefix,
    inputRef,
    itemClassName,
    onChange,
    onClick,
    other,
    radios,
    ...remainingProps
  } = props;

  let refProps = inputRef ? { ref: inputRef } : {};
  remainingProps = { ...remainingProps, ...refProps };

  return (
    <>
      {radios.map((r, index) => {
        let { ...remainingProps2 } = remainingProps;

        if (currentValue !== undefined) {
          const checked = r.value === currentValue;
          remainingProps2 = { ...remainingProps2, checked } as any;
        }

        if (onChange) {
          remainingProps2 = {
            ...remainingProps2,
            onChange: () => (onChange as any)(r.value),
          } as any;
        }
        if (onClick) {
          remainingProps2 = {
            ...remainingProps2,
            onClick: () => (onClick as any)(r.value),
          } as any;
        }

        return (
          <Fragment key={index}>
            <RadioWithLabel
              {...remainingProps2}
              required={r.required}
              disabled={r.disabled}
              idPrefix={idPrefix}
              value={r.value}
              label={r.label}
              className={itemClassName}
            />
            {afterEachOption && afterEachOption(r.value)}
            {!props.disabled && other && index === other.index && (
              <FieldOptionLayout label={other.content} className="my-2" />
            )}
          </Fragment>
        );
      })}
    </>
  );
}
