/*!
 * The side car menu displays the content of the PrimaryNavigation, for mobile devices only.
 */

import SideCar from "components/nav/SideCar";
import { useHamburgerStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";

function SideCarMenu() {
  const store = useHamburgerStore();

  return (
    <>
      {store?.isOpen && (
        <div
          className="absolute inset-0 z-10 cursor-e-resize"
          style={{ transform: "translatex(-16rem) translatez(0)" }}
          onClick={() => store.close()}
        >
          &nbsp;
        </div>
      )}
      <SideCar />
    </>
  );
}

export default observer(SideCarMenu);
