/*!
 * PageContent plugin for WebComponents
 *
 * The ContentItem must have a `uri` that matches the current page.
 */

import { observer } from "mobx-react-lite";
import { buildClassName } from "lib/string";
import CMSPage from "components/CMSPage";

interface Props {
  className?: string;
  parentTag?: string;
  titleColor?: string;
}

const PageContent = (props: Props) => {
  let { className, parentTag } = props;

  return (
    <div
      className={buildClassName(parentTag, "PageContent", "grow", className)}
    >
      <CMSPage />
    </div>
  );
};

export default observer(PageContent);
