import { useEffect, useState } from "react";

interface Props {
  className?: string;
  size?: number;
  // Delay showing the spinner for half a second, to avoid unnecessary visual noise
  withDelay?: boolean;
}

export default function ProgressIndicator(props: Props) {
  const { className } = props;
  const withDelay = props.withDelay ?? true;
  const size = props.size || 36;
  const [show, setShow] = useState(withDelay !== true);

  useEffect(() => {
    if (!withDelay) return;
    const timeout = setTimeout(() => setShow(true), 500);
    return () => clearTimeout(timeout);
  }, [withDelay]);

  if (!show) return null;

  return (
    <img
      src="/static/images/spinner3-black.gif"
      width={size}
      height={size}
      className={className}
    />
  );
}
