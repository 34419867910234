import { useRouter } from "next/router";
import { ThemeArea, ThemeInfo } from "types/theme";

export function useThemeInfo(): ThemeInfo {
  const router = useRouter();
  let path = router.asPath;
  if (typeof window !== "undefined") {
    path = window.location.pathname;
  }
  return calculateThemeInfo(path);
}

export function calculateThemeInfo(uri: string): ThemeInfo {
  uri = uri.replace(/\?.*/, "");
  let themeArea: ThemeArea = "marketing";
  let isMarketingArea = false;
  let isCustomerThemeable = true;
  let areaClass = "";

  if (uri.startsWith("/_admin")) {
    themeArea = "admin";
    isCustomerThemeable = false;
    areaClass = "gf-area-admin";
  } else if (
    uri.startsWith("/members") ||
    (uri.startsWith("/events") && !uri.startsWith("/events/public"))
  ) {
    themeArea = "members";
    areaClass = "gf-area-members gf-customer";
  } else {
    isMarketingArea = true;
    areaClass = "gf-area-marketing gf-customer";
  }

  return {
    areaClass,
    isCustomerThemeable,
    isMarketingArea,
    themeArea,
  };
}
