interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Code(props: Props) {
  const { children, className } = props;

  return (
    <code className={`bg-gray-lighter px-1 ${className || ""}`}>
      {children}
    </code>
  );
}
