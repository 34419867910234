import { ApiResponse } from "types/api";
import { MutationResult } from "types/mutation";

export function buildResultFromApiResult(
  apiResult: ApiResponse<any>,
  message: string
): MutationResult {
  if (!apiResult.error) {
    return { type: "success", message };
  }

  if (apiResult.serverError) {
    return {
      type: "error",
      message:
        "Failed to service your request. There was a problem with this service."
    };
  } else {
    return {
      type: "invalid",
      message
    };
  }
}
