// Automatically focus on an input field in a form

import { useRef } from "react";

export default function useAutoFocus() {
  const inputFocused = useRef(false);

  const registerRef = function (el: HTMLElement | null) {
    if (el && !inputFocused.current) {
      el.focus();
      inputFocused.current = true;
    }
  };

  return registerRef;
}
