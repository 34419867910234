import SimpleModal from "components/SimpleModal";
import LoginForm from "./LoginForm";
import { useLoginModalStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";

function LoginModal() {
  const store = useLoginModalStore();

  return (
    <SimpleModal isOpen={store?.isOpen} onClose={() => store?.close()}>
      <LoginForm />
    </SimpleModal>
  );
}

export default observer(LoginModal);
