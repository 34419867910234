// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}

export default function GreenCircleIcon(props: Props) {
  const { className, style } = props;
  const otherProps = {} as any;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
      className={className}
      style={style}
      title="Green light"
      {...otherProps}
    >
      <defs>
        <linearGradient
          id="3jXOdEohtKfkAeVV9N72va"
          x1="-792"
          x2="-792"
          y1="6"
          y2="42"
          gradientTransform="translate(816)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#87eb78" />
          <stop offset="1" stopColor="#29d112" />
        </linearGradient>
        <radialGradient
          id="3jXOdEohtKfkAeVV9N72vb"
          cx="-792"
          cy="24"
          r="18"
          gradientTransform="translate(816)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7beb6c" stopOpacity="0" />
          <stop offset=".4" stopColor="#7aea6b" stopOpacity=".01" />
          <stop offset=".544" stopColor="#76e867" stopOpacity=".048" />
          <stop offset=".647" stopColor="#70e560" stopOpacity=".114" />
          <stop offset=".73" stopColor="#67df56" stopOpacity=".209" />
          <stop offset=".801" stopColor="#5ad84a" stopOpacity=".332" />
          <stop offset=".864" stopColor="#4cd03a" stopOpacity=".484" />
          <stop offset=".921" stopColor="#3ac628" stopOpacity=".665" />
          <stop offset=".972" stopColor="#26ba13" stopOpacity=".868" />
          <stop offset="1" stopColor="#19b305" />
        </radialGradient>
      </defs>
      <circle cx="24" cy="24" r="18" fill="url(#3jXOdEohtKfkAeVV9N72va)" />
      <circle cx="24" cy="24" r="18" fill="url(#3jXOdEohtKfkAeVV9N72vb)" />
    </svg>
  );
}
