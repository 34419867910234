// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import Style from "lib/Style";

interface Props {
  children: React.ReactNode;
  className?: string;
  spacing?: "small" | "medium" | "large";
  style?: any;
}

export default function ColumnLabel(props: Props) {
  let { children, className, spacing } = props;
  let minHeight = "2.5em";
  if (spacing === "small") {
    minHeight = "1.0em";
  }

  let style = {
    minHeight,
    width: "12em",
    minWidth: "12em",
    maxWidth: "12em",
  };

  if (props.style) {
    style = { ...style, ...props.style };
  }

  return (
    <label
      className={`inline-block ${Style.labelText} mr-2 ${className || ""}`}
      style={style}
    >
      {children}
    </label>
  );
}
