"use client";

// UIs that appear globally in the application, such SideCar menu, login modal, etc.

import LoginModal from "components/login/LoginModal";
import SideCarMenu from "./SideCarMenu";
import PasswordSetPrompt from "components/member/PasswordSetPrompt";
import ContentEditing from "components/contentEditing/ContentEditing";
import GroupSwitcher from "components/dev/GroupSwitcher";

export function GlobalUIs() {
  console.log("GlobalUIs render");
  return (
    <>
      <SideCarMenu />
      <LoginModal />
      <PasswordSetPrompt />
      <ContentEditing />
      <GroupSwitcher />
    </>
  );
}
