import { UseFormReturn } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import TextInput from "./TextInput";
import { useRef } from "react";

interface Props {
  focusOnLoad?: boolean;
  form: UseFormReturn<any>;
}

export default function NameField(props: Props) {
  const { form } = props;
  const { register } = form;
  const origRegisterProps = register("name", {
    required: true,
  });
  const focusHandledRef = useRef<boolean>(false);

  const inputRef = (el: HTMLInputElement | null) => {
    origRegisterProps.ref(el);
    if (el && props.focusOnLoad && !focusHandledRef.current) {
      el.focus();
      focusHandledRef.current = true;
    }
  };

  const registerProps = { ...origRegisterProps, ref: inputRef };

  return (
    <>
      <TextInput register={registerProps} />
      <ErrorMessage
        name="name"
        errors={form.formState.errors}
        messages={{
          required: "Name is required",
          maxLength: "Name is too long",
        }}
      />
    </>
  );
}
