import { gmtOffset } from "lib/constants";
import { formatDate } from "lib/date";
import { buildClassName } from "lib/string";

interface Props {
  date: Date | string;
  textClassName?: string;
}

export default function TimezoneOffset({ date, textClassName }: Props) {
  return (
    <span className={buildClassName(textClassName || "text-gray-dark text-xs")}>
      {formatDate(date, gmtOffset)}
    </span>
  );
}
