import Flash from "components/Flash";
import { ErrorMessage, SubmitButton } from "components/form";
import PasswordInput from "components/form/PasswordInput";
import RowLabel from "components/profileEdit/RowLabel";
import ProgressIndicator from "components/ProgressIndicator";
import { useHookFormAutoFocus } from "hooks/useHookFormAutoFocus";
import { submitIsDisabled } from "lib/form";
import { UseFormReturn } from "react-hook-form";
import { LoginStatus } from "types/auth";

interface PasswordForm {
  email: string;
  password: string;
}

interface Props {
  error: string;
  form: UseFormReturn<any>;
  loginStatus: LoginStatus;
  onSubmit: (data: PasswordForm) => void;
}

export default function PasswordPrompt(props: Props) {
  const { error, loginStatus, onSubmit } = props;
  const form = props.form as UseFormReturn<PasswordForm>;
  const { formState, handleSubmit, register, watch } = form;
  const { errors } = formState;
  const wrapRegister = useHookFormAutoFocus();

  const showSuccessOrFailure = () => {
    if (loginStatus === "failedLogin") {
      return (
        <div className="mb-2">
          <Flash type="warning">
            <span>
              Incorrect email or password for email <b>{watch("email")}</b>
            </span>
          </Flash>
        </div>
      );
    }
    if (loginStatus === "loggedIn") {
      return <Flash className="mb-2">Success...</Flash>;
    }

    return null;
  };

  return (
    <form className="rounded-xl bg-white p-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <RowLabel>Password</RowLabel>
        <PasswordInput
          register={wrapRegister(register("password", { required: true }))}
        />
        <ErrorMessage
          name="password"
          errors={errors}
          messages={{ required: "password is required" }}
        />
      </div>

      {showSuccessOrFailure()}
      <div>
        {error && (
          <Flash type="warning" className="mb-2">
            {error}
          </Flash>
        )}
        {loginStatus === "loggingIn" ? (
          <ProgressIndicator />
        ) : (
          <div className="flex justify-center">
            <SubmitButton
              id="emailPasswordLoginBtn"
              type="submit"
              paddingClass="px-12 py-2"
              disabled={submitIsDisabled(formState)}
            >
              Login
            </SubmitButton>
          </div>
        )}
      </div>
    </form>
  );
}
