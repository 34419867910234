/*!
 * Functions to support WebComponents
 *
 * WebComponents are user-definable components for building web pages.
 * The are JSON-serializable, and they are converted to VDOM (virtual dom) nodes before
 * being rendered into React components.
 */

import {
  ChildUsage,
  WebComponent,
  WebComponentRoute,
  WebComponentType,
  WebSiteDef,
} from "types/web-component";
import token from "./token";
import { GroupConfig } from "types/group";

export const webComponentTypes: { value: WebComponentType; label: string }[] = [
  { value: "TEXT", label: "Text" },
  { value: "HTML", label: "HTML" },
  { value: "REFERENCE", label: "Reference" },
  { value: "GF_PRESET", label: "Preset" },
];

export const webComponentChildUseTypes: { value: ChildUsage; label: string }[] =
  [
    { value: "ATTRIBUTE", label: "Attribute" },
    { value: "TEMPLATE", label: "Template" },
    { value: "PASSTHROUGH", label: "Passthrough" },
    { value: "OVERRIDE", label: "Override" },
  ];

export function isWebSite2(groupConfig: GroupConfig): boolean {
  return !!groupConfig.webSiteAssets;
}

export function getByKey(components: WebComponent[], key: string) {
  return components.find((c) => c.key === key);
}

export function generateCmpId() {
  return "cmp-" + token();
}

// Convert a WebSite to a format suitable for sending to the API
export function encodeWebSite(site: WebSiteDef): any {
  let { id, key, __typename, ...site2 } = site as any;
  const attrs = (site.attrs || []).map((attr) => encodeAttr(attr));
  let encodedSite = { ...site2, attrs } as any;
  const encodedComponents = site.components.map((cmp) =>
    encodeWebComponent(cmp)
  );
  encodedSite = { ...encodedSite, components: encodedComponents };
  return encodedSite;
}

function encodeAttr(attr: WebComponent) {
  return encodeWebComponent(attr);
}

// Convert a WebComponent to a format suitable for sending to the API
export function encodeWebComponent(
  cmp: WebComponent,
  opts?: { embedded?: boolean }
): any {
  const embedded = opts?.embedded ?? false;

  let { id, __typename, attrs, ...cmp2 } = cmp as any;
  // if id is a UUID
  if (id && id.length === 36) {
    cmp2 = { ...(cmp2 as any), id };
  }

  // Remove null or undefined type
  if (!cmp.type) {
    const { type, ...cmp3 } = cmp2;
    cmp2 = cmp3;
  }

  return embedded ? JSON.stringify(cmp2) : cmp2;
}

// Nest attributes within components
// The API returns a WebSite with all the component attributes flattened
// and mixed in with the components. This function nests the attributes
// to their appropriate parent-child relationship.
export function nestWebSite(site: WebSiteDef) {
  const parents = nestComponents(site.components);
  return { ...site, parents };
}

function nestComponents(components: WebComponent[]) {
  // Group components by their parent ID
  const childrenByParentId = components.reduce(
    (acc: any, cmp: WebComponent) => {
      const parentId = cmp.parentId;
      if (parentId) {
        let children = acc[parentId] || [];
        children = [...children, cmp];
        return { ...acc, [parentId]: children };
      } else {
        return acc;
      }
    },
    {}
  );

  // Get top-level components
  return components
    .filter((cmp) => !cmp.parentId)
    .map((cmp) => attachAttrs(cmp, childrenByParentId));
}

function attachAttrs(
  cmp: WebComponent,
  byParentMap: { [key: string]: WebComponent[] }
) {
  let children: WebComponent[] = byParentMap[cmp.id as string] || [];
  children = children.map((child) => {
    return attachAttrs(child, byParentMap);
  });

  return { ...cmp, attrs: children };
}

export function flattenWebSite(site: WebSiteDef) {
  const components = flattenComponents(site.components);
  return { ...site, components };
}

function flattenComponents(components: WebComponent[]): WebComponent[] {
  return components.reduce((acc: WebComponent[], cmp: WebComponent) => {
    const { attrs, ...cmp2 } = cmp;
    const flattenedAttrs = flattenComponents(attrs || []);
    return [...acc, cmp2, ...flattenedAttrs];
  }, [] as WebComponent[]);
}

export function resolveRoute(uri1: string, routes: WebComponentRoute[]) {
  // Filter routes that have a route
  routes = routes.filter((r) => r.route);
  const uri = uri1.split("#")[0];

  const route = routes.find((r) => r.route === uri);
  if (route) {
    return route;
  }

  // For each route, remove the wildcard pattern and see if the uri starts with it
  const matchingRoutes = routes.filter((r) => {
    if (r.route.endsWith("*")) {
      const pattern = (r.route as string).replace(/\/?\*$/, "");
      return uri.startsWith(pattern);
    } else {
      return false;
    }
  });

  // Sort routes by longest route first
  const sortedRoutes = matchingRoutes.sort((a, b) => {
    return (b.route as string).length - (a.route as string).length;
  });

  return sortedRoutes[0];
}
