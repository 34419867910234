// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  otherProps?: any;
}

export default function CloseIcon(props: Props) {
  const style = props.style;
  const strokeRatio = 0.085;
  const otherProps = props.otherProps || {};

  const specs = {
    totalWidth: 200,
    square: {
      radiusRatio: 0.2,
      strokeWidthRatio: strokeRatio,
    },
    cross: {
      strokeRatio,
    },
  };

  function box() {
    const { totalWidth, square } = specs;
    const strokeWidth = square.strokeWidthRatio * totalWidth;
    const radius = square.radiusRatio * totalWidth;
    const side = totalWidth - radius * 2 - strokeWidth;
    const displayGrid = false;

    return (
      <>
        {displayGrid && (
          <rect
            width={totalWidth}
            height={totalWidth}
            fill="none"
            style={{ strokeWidth: 1, stroke: "rgb(0,0,0)" }}
          />
        )}
        <path
          name="rounded-box"
          d={`
                M ${strokeWidth / 2 + radius} ${strokeWidth / 2}
                h ${side}
                q ${radius} 0 ${radius} ${radius}
                v ${side}
                q 0 ${radius} -${radius} ${radius}
                h -${side}
                q -${radius} 0 -${radius} -${radius}
                v -${side}
                q 0 -${radius} ${radius} -${radius}
              `}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="none"
        />
      </>
    );
  }

  function cross() {
    const { totalWidth, cross } = specs;
    const halfWidth = totalWidth / 2;
    const radiusRatio = cross.strokeRatio;
    const radius = totalWidth * radiusRatio;
    const diameter = radius * 2;
    const curveGuideLength = radius * 1.33;
    const sideLength = totalWidth - diameter;

    return (
      <>
        <g
          name="cross"
          transform={`
            scale(0.5)
            translate(${halfWidth} ${halfWidth})
            rotate(45 ${halfWidth} ${halfWidth})
          `}
        >
          <path
            name="horizontal-line"
            d={`
                  M ${radius} ${halfWidth - radius}
                  h ${sideLength}
                  c ${curveGuideLength} 0 ${curveGuideLength} ${diameter} 0 ${diameter}
                  h -${sideLength}
                  c -${curveGuideLength} 0 -${curveGuideLength} -${diameter} 0 -${diameter}
                `}
            fill="currentColor"
          />
          <path
            name="vertical-line"
            d={`
                M ${halfWidth + radius} ${radius}
                v ${sideLength}
                c 0 ${curveGuideLength} -${diameter} ${curveGuideLength} -${diameter} 0
                v -${sideLength}
                c 0 -${curveGuideLength} ${diameter} -${curveGuideLength} ${diameter} 0
              `}
            fill="currentColor"
          />
        </g>
      </>
    );
  }

  return (
    <svg
      width="200"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      style={style}
      className={props.className}
      onClick={props.onClick}
      title="Close"
      {...otherProps}
    >
      {box()}
      {cross()}
    </svg>
  );
}
