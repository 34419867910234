// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ApiCall, ApiResponse } from "types/api";
import {
  ContentItem,
  ContentItemExtended,
  ContentItemParams,
  ContentItemsListParams,
} from "types/content";
import { GroupConfig } from "types/group";
import {
  fetchAndLog,
  getS3EnvBaseUrl,
  gfGqlHeaders,
  wrapApiResponse,
  wrapGqlResponse,
} from "./api-util";
import { parseDate } from "./apiDecode";
import { graphqlSingleCall, stringifyArgs } from "./gql";
import { gql } from "@apollo/client";

const contentItemSettingsAttrs =
  "isAccountMenu isMobileVisible isLoggedInVisible isLoggedOutVisible role";

const styleFields = ["value", "name"];

const contentItemBaseFields = [
  "baseType",
  "content",
  "contentType",
  "help",
  "id",
  "internalName",
  "label",
  "order",
  "parentId",
  "photoId",
  "plurality",
  "publishedAt",
  "rootUri",
  `settings { ${contentItemSettingsAttrs} }`,
  "slug",
  "status",
  `styles { ${styleFields.join(" ")} }`,
  "subType",
  "title",
  "uri",
];
const contentItemBaseAttrs = contentItemBaseFields.join("\n");

export async function listContentItems2(
  call: ApiCall,
  params: Partial<ContentItemsListParams>
): Promise<ApiResponse<ContentItem[]>> {
  const endpointUrl = call.baseUrl + "/gql?op=GetContentItems";
  const argsString = stringifyArgs(params);
  const query = `{contentItems(${argsString}) { ${contentItemBaseAttrs} }}`;
  let response = await fetchAndLog(endpointUrl, {
    method: "POST",
    headers: gfGqlHeaders(call),
    body: JSON.stringify({ query }),
  });
  const body = await response.json();
  return wrapGqlResponse(response, body, "contentItems");
}

export async function listContentItemsFromS3(
  baseType: "nav" | "text",
  groupConfig: GroupConfig
): Promise<ApiResponse<ContentItem[]>> {
  const path = `/${baseType}.json`;
  const baseUrl = getS3EnvBaseUrl(groupConfig);
  const endpointUrl = baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {});
  return wrapApiResponse(response, (items) => items.map(parseContentItem));
}

export async function getContentItem(
  call: ApiCall,
  arg: any
): Promise<ApiResponse<ContentItemExtended>> {
  const path = `/gql?op=contentItem`;
  const endpointUrl = call.baseUrl + path;
  let args;
  if (typeof arg === "string") {
    args = { id: arg };
  } else if (typeof arg === "object") {
    args = arg;
  }

  const query =
    "{\n" +
    `  contentItem(${stringifyArgs(args)}) {\n` +
    `    ${contentItemBaseAttrs}\n` +
    "    author { id name title }\n" +
    "    children {\n" +
    `      ${contentItemBaseAttrs}\n` +
    "      photo {\n" +
    "        url\n" +
    "        thumbUrl\n" +
    "      }\n" +
    "    }" +
    "  }" +
    "}";

  const response = await fetchAndLog(endpointUrl, {
    method: "POST",
    headers: gfGqlHeaders(call),
    body: JSON.stringify({ query }),
  });
  const body = await response.json();
  return wrapGqlResponse(
    response,
    body,
    "contentItem",
    (data) => parseContentItem(data) as ContentItemExtended
  );
}

export async function createContentItem2(
  call: ApiCall,
  params: Partial<ContentItemParams>
): Promise<ApiResponse<ContentItem>> {
  return graphqlSingleCall<ContentItem>({
    call,
    opName: "CreateContentItem",
    opType: "mutation",
    query: {
      variables: { params },
      argTypes: { params: "ContentItemParams" },
      qName: "createContentItem",
      returnNames: contentItemBaseFields,
    },
  });
}

export async function updateContentItem2(
  call: ApiCall,
  params: Partial<ContentItemParams>
): Promise<ApiResponse<ContentItem>> {
  return graphqlSingleCall<ContentItem>({
    call,
    opName: "UpdateContentItem",
    opType: "mutation",
    query: {
      variables: { id: params.id, params },
      argTypes: { id: "ID", params: "ContentItemParams" },
      qName: "updateContentItem",
      returnNames: contentItemBaseFields,
    },
  });
}

export async function bulkUpdateContentItems(
  call: ApiCall,
  paramsList: Partial<ContentItem>[]
): Promise<ApiResponse<void>> {
  const endpointUrl = call.baseUrl + "/gql?op=bulkUpdateContentItems";
  const args = stringifyArgs({ params: { list: paramsList } });
  const query =
    "mutation BulkUpdateContentItems {\n" +
    `  bulkUpdateContentItems(${args}) { ok }\n` +
    "}";

  const body = { query };

  let response = await fetchAndLog(endpointUrl, {
    method: "POST",
    body: JSON.stringify(body),
    headers: gfGqlHeaders(call),
  });

  const respBody = await response.json();
  return wrapGqlResponse(response, respBody, "bulkUpdateContentItems");
}

export async function deleteContentItem(
  call: ApiCall,
  id: string
): Promise<ApiResponse<ContentItem>> {
  const endpointUrl = call.baseUrl + "/gql?op=deleteContentItem";
  const args = stringifyArgs({ id });
  const query =
    "mutation DeleteContentItem {\n" +
    `  deleteContentItem(${args}) { id }\n` +
    "}";

  let response = await fetchAndLog(endpointUrl, {
    method: "POST",
    body: JSON.stringify({ query }),
    headers: gfGqlHeaders(call),
  });

  const body = await response.json();
  return wrapGqlResponse(response, body, "deleteContentItem");
}

function parseContentItem(data: any): ContentItem {
  let result = {
    ...data,
    publishedAt: data.publishedAt && parseDate(data.publishedAt),
  };

  if (result.children) {
    result = { ...result, children: result.children.map(parseContentItem) };
  }

  return result;
}

export const LIST_NAV_MENUS = gql`
  query ListContentItems($filter: ContentItem2FilterInput) {
    listContentItems(filter: $filter) {
      id
      label
    }
  }
`;

export const LIST_SIMPLE_CONTENT_ITEMS = gql`
  query ListContentItems($filter: ContentItem2FilterInput) {
    listContentItems(filter: $filter) {
      id
      internalName
      plurality
      slug
      subType
      title
    }
  }
`;

export const CLONE_CONTENT_ITEM = gql`
  mutation CloneContentItem($id: ID!) {
    cloneContentItem(id: $id) {
      baseType
      content
      contentType
      help
      id
      internalName
      label
      order
      parentId
      photoId
      plurality
      publishedAt
      rootUri
      settings { ${contentItemSettingsAttrs} }
      slug
      status
      styles { ${styleFields.join(" ")} }
      subType
      title
      title
      uri
    }
  }
`;
