import TextInput from "components/form/TextInput";
import React, { useEffect, useState } from "react";
import CheckboxWithLabel2 from "./CheckBoxWithLabel2";
import clsx from "clsx";

interface Props {
  allowShowPassword?: boolean;
  id?: string;
  masked?: boolean;
  name?: string;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onShowPasswordToggle?: (showPassword: boolean) => void;
  readOnly?: boolean;
  register?: any;
  required?: boolean;
  showPassword?: boolean;
  value?: string | null;
}

const fieldRef: React.RefObject<HTMLInputElement> = React.createRef();
const hiddenRef: React.RefObject<HTMLInputElement> = React.createRef();

export default function PasswordInput(props: Props) {
  const { onKeyDown, value: password, id } = props;
  const allowShowPassword = props.allowShowPassword ?? true;
  const [showPassword, setShowPassword] = useState<boolean>(
    props.showPassword || false
  );

  useEffect(() => {
    setShowPassword(props.showPassword || false);
  }, [props.showPassword]);

  function onChange(e: any) {
    props.onChange && props.onChange(e);
    const value = e.target.value;
    const hiddenEl = hiddenRef.current;
    if (hiddenEl) {
      hiddenEl.value = value;
    }
  }

  function onShowChange() {
    if (props.onShowPasswordToggle) {
      props.onShowPasswordToggle(!showPassword);
    } else {
      setShowPassword(!showPassword);
    }
  }

  const readOnlyClass = props.readOnly ? "text-gray-darker" : "";
  const className = readOnlyClass;
  let additionalProps = {};
  if (props.register) {
    additionalProps = { ...props.register };
  } else {
    additionalProps = {
      name: props.name,
      onChange,
      onKeyDown,
      inputRef: fieldRef,
      value: password,
      required: props.required,
    };
  }
  const name = props.register?.name || props.name;

  return (
    <>
      <span className="block relative">
        <TextInput
          id={id}
          readOnly={props.readOnly}
          type={showPassword ? "text" : "password"}
          {...additionalProps}
          className={clsx(className, "font-mono")}
          autoComplete="on"
          placeholder="Password"
        />
        {allowShowPassword && (
          <div className="mt-2">
            <CheckboxWithLabel2
              label="Show password"
              onChange={onShowChange}
              name={name}
            />
          </div>
        )}
      </span>
    </>
  );
}
