// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import phoenixTypes from "types/phoenix";
import {
  Channel,
  ExtendedMessage,
  LiveChannel,
  Message,
} from "types/messaging";
import { connectWebSocket } from "lib/gf-websocket";
import { claimsAreValid, jwtIsValid } from "lib/jwt";
import JwtStore from "./JwtStore";
import { Map } from "immutable";
import { parseDate } from "lib/gf-api/apiDecode";
import { loginToContinue } from "lib/auth";
import { parseChannel } from "lib/gf-api";

function markMessagesAsRead(
  messages: ExtendedMessage[],
  lastReadMessageId: string | null
) {
  let updatedMessages = [] as ExtendedMessage[];
  let foundRead = false;
  let message;
  for (let i = messages.length - 1; i >= 0; i--) {
    message = messages[i];
    if (message.id === lastReadMessageId) {
      foundRead = true;
    }
    message = { ...message, read: foundRead };
    updatedMessages = [message, ...updatedMessages];
  }

  return updatedMessages;
}

export default class ChannelStore {
  channels: Channel[] | null;
  jwtStore: JwtStore;
  liveChannels: LiveChannel[] | null;
  memberChannel: phoenixTypes.Channel | null;
  messages: Map<string, ExtendedMessage[]>;
  groupSlug: string;
  socketUrl: string;
  startedConnectingWebSocket: boolean;
  startedMemberChannel: boolean;
  webSocket: phoenixTypes.Socket | null;

  constructor(wsBaseUrl: string, jwtStore: JwtStore, groupSlug: string) {
    this.channels = null;
    this.jwtStore = jwtStore;
    this.liveChannels = null;
    this.memberChannel = null;
    this.messages = Map();
    this.groupSlug = groupSlug;
    this.socketUrl = wsBaseUrl;
    this.startedConnectingWebSocket = false;
    this.startedMemberChannel = false;
    this.webSocket = null;
    makeObservable(this, {
      channels: observable,
      liveChannels: observable,
      messages: observable,
      setChannels: action,
      setLiveChannels: action,
      unreadCount: computed,
      webSocket: observable,
    });
  }

  getWebSocket() {
    if (typeof window === "undefined") return null;
    if (this.webSocket) return this.webSocket;
    const jwt = this.jwtStore.memberJwt;
    if (!jwt) {
      console.warn("Attempt to connect to WebSocket without JWT");
      return null;
    }
    if (!jwtIsValid(jwt)) {
      console.warn("Attempt to connect to WebSocket with invalid JWT");
      return null;
    }
    if (!this.webSocket && !this.startedConnectingWebSocket) {
      this.startedConnectingWebSocket = true;
      this.webSocket = connectWebSocket(this.socketUrl, jwt, this.groupSlug);
      (this.webSocket as any).onError(() => {
        let disconnect = false;
        if (
          this.jwtStore.validClaims &&
          !claimsAreValid(this.jwtStore.validClaims)
        ) {
          disconnect = true;
        } else if (!this.jwtStore.validClaims) {
          disconnect = true;
        }

        if (disconnect) {
          console.log("JWT is no longer valid. Disconnecting.");
          (this.webSocket as any).disconnect();
          runInAction(() => {
            this.webSocket = null;
          });
        }
      });
    }
    return this.webSocket;
  }

  async loadMemberChannelAndConversations() {
    await this.getMemberChannel();
    if (this.memberChannel) {
      this.loadChannels();
    }
  }

  async getMemberChannel() {
    if (typeof window === "undefined") return null;

    if (!this.startedMemberChannel) {
      this.startedMemberChannel = true;
      const socket = this.getWebSocket();
      if (!socket) {
        throw new Error("getMemberChannel: No socket set");
      }

      const claims = this.jwtStore.validClaims;
      if (!claims) {
        throw new Error("No claims found");
      }
      const sub = claims.sub;
      if (!sub) {
        throw new Error("No valid claims found");
      }
      // This works either with a MemberId or LinkedAccountId
      const channelName = `member:${sub}`;
      this.memberChannel = socket.channel(channelName, {});

      this.memberChannel.join().receive("error", (resp: any) => {
        console.warn("Unable to join", resp);
      });

      this.memberChannel.on("message", (encoded: any) => {
        const message = this.decodeMessage(encoded) as Message;
        this.appendMessageForChannel(message);

        let channel =
          this.channels &&
          this.channels.find((ch) => ch.id === message.channelId);
        if (!channel) {
          this.loadChannels();
        }
      });

      this.memberChannel.on("reload", () => {
        if (typeof window !== "undefined") {
          window.location.reload();
        }
      });

      this.memberChannel.on("logout", () => {
        const jwt = this.jwtStore.jwtValue;
        if (jwt) {
          loginToContinue();
        }
      });
    }

    return this.memberChannel as phoenixTypes.Channel;
  }

  loadChannelMessages(channelId: string): Promise<boolean> {
    if (!this.memberChannel) {
      return Promise.resolve(false);
    }

    const store = this;

    return new Promise<boolean>((resolve) => {
      (store.memberChannel as phoenixTypes.Channel)
        .push("getChannelMessages", { channelId })
        .receive("ok", (resp: any) => {
          const { channelId, lastReadMessageId } = resp;
          let messages = resp.messages.map((m: any) => store.decodeMessage(m));
          const updatedMessages = markMessagesAsRead(
            messages,
            lastReadMessageId
          );
          store.setMessagesForChannel(channelId, updatedMessages);
          resolve(true);
        })
        .receive("error", () => {
          resolve(false);
        });
    });
  }

  decodeMessage(message: any): ExtendedMessage | null {
    if (!message) {
      return null;
    }

    return {
      ...message,
      sentAt: parseDate(message.sentAt as any) as Date,
      read: false,
    };
  }

  setChannels(channels: Channel[]) {
    this.channels = channels;
    this.messages = this.channels.reduce(
      (messages: Map<string, ExtendedMessage[]>, channel: Channel) => {
        let initialMessages = messages.get(channel.id);

        if (channel.lastReadMessageId && initialMessages) {
          const updatedMessages = markMessagesAsRead(
            initialMessages,
            channel.lastReadMessageId
          );
          return messages.set(channel.id, updatedMessages);
        } else {
          return messages;
        }
      },
      this.messages
    );
  }

  updateChannelMessagesAsRead(channelId: string, lastReadMessageId: string) {
    let messages = this.getMessagesForChannel(channelId);
    if (!messages) {
      return;
    }
    messages = markMessagesAsRead(messages, lastReadMessageId);
    this.setMessagesForChannel(channelId, messages);
  }

  get unreadCount() {
    if (!this.channels) return 0;
    return this.channels.reduce<number>(
      (count: number, ch: Channel) =>
        count + this.getUnreadCountForChannel(ch.id),
      0
    );
  }

  setLiveChannels(channels: LiveChannel[]) {
    this.liveChannels = channels;
  }

  setMessagesForChannel(channelId: string, messages: Message[]) {
    runInAction(() => {
      this.messages = this.messages.set(channelId, messages);
    });
  }

  appendMessageForChannel(message: Message) {
    let messages = this.messages.get(message.channelId);

    runInAction(() => {
      if (messages) {
        messages = [...(messages as ExtendedMessage[]), message];
        this.messages = this.messages.set(message.channelId, messages);
      }

      if (message.channelUnreadCount !== undefined && this.channels) {
        this.channels = this.channels.map((ch) => {
          if (ch.id === message.channelId) {
            return {
              ...ch,
              unreadCount: message.channelUnreadCount || ch.unreadCount,
            };
          }
          return ch;
        });
      }
    });
  }

  getMessagesForChannel(channelId: string): Message[] | undefined {
    return this.messages.get(channelId);
  }

  getUnreadCountForChannel(channelId: string): number {
    const messages = this.getMessagesForChannel(channelId);
    if (!messages) {
      if (this.channels) {
        const channel = this.channels.find((c) => c.id === channelId);
        if (channel) {
          return channel.unreadCount;
        }
      }

      return 0;
    }

    return messages.reduce(
      (count, message) => (message.read ? count : count + 1),
      0
    );
  }

  loadChannels(): Promise<boolean> {
    const store = this;
    const { memberChannel } = this;
    if (!memberChannel) throw new Error("memberChannel not set");

    return new Promise((resolve) => {
      memberChannel
        .push("getChannels", {})
        .receive("ok", (resp: any) => {
          const channels = (resp.channels as any[]).map(parseChannel);
          store.setChannels(channels);
          resolve(true);
        })
        .receive("error", () => {
          resolve(false);
        });
    });
  }
}
