// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}

export default function OrgChartIcon(props: Props) {
  const { className, style } = props;
  const otherProps = {} as any;

  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50px"
      height="50px"
      className={className}
      style={style}
      title="Org chart icon"
      {...otherProps}
    >
      <path d="M 6 2 A 1.0001 1.0001 0 0 0 5 3 L 5 13 A 1.0001 1.0001 0 0 0 6 14 L 13 14 L 13 24.832031 A 1.0001 1.0001 0 0 0 13 25.158203 L 13 42 A 1.0001 1.0001 0 0 0 14 43 L 27 43 L 27 47 A 1.0001 1.0001 0 0 0 28 48 L 44 48 A 1.0001 1.0001 0 0 0 45 47 L 45 37 A 1.0001 1.0001 0 0 0 44 36 L 28 36 A 1.0001 1.0001 0 0 0 27 37 L 27 41 L 15 41 L 15 26 L 27 26 L 27 30 A 1.0001 1.0001 0 0 0 28 31 L 44 31 A 1.0001 1.0001 0 0 0 45 30 L 45 20 A 1.0001 1.0001 0 0 0 44 19 L 28 19 A 1.0001 1.0001 0 0 0 27 20 L 27 24 L 15 24 L 15 14 L 22 14 A 1.0001 1.0001 0 0 0 23 13 L 23 3 A 1.0001 1.0001 0 0 0 22 2 L 6 2 z M 7 4 L 21 4 L 21 12 L 14.154297 12 A 1.0001 1.0001 0 0 0 13.984375 11.986328 A 1.0001 1.0001 0 0 0 13.839844 12 L 7 12 L 7 4 z M 29 21 L 43 21 L 43 29 L 29 29 L 29 25.167969 A 1.0001 1.0001 0 0 0 29 24.841797 L 29 21 z M 29 38 L 43 38 L 43 46 L 29 46 L 29 42.167969 A 1.0001 1.0001 0 0 0 29 41.841797 L 29 38 z" />
    </svg>
  );
}
