import { MiniContentItem } from "types/content";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import TitleHeading from "./modal/TitleHeading";
import TitleSubHeading from "./modal/TitleSubHeading";
import TitleWrapper from "./modal/TitleWrapper";
import TextSubTypeHeading from "./TextSubTypeHeading";
import BackButtonWithLabel from "components/ui/BackButtonWithLabel";

interface Props {
  contentItem?: Partial<MiniContentItem>;
  onBackClick?: () => void;
  heading?: string | React.ReactNode;
}

export default function TextItemHeader(props: Props) {
  const { contentItem, heading, onBackClick } = props;

  return (
    <TitleWrapper>
      <BackButtonWithLabel onClick={onBackClick} className="mr-3" />
      <TitleHeading>
        {heading}
        {contentItem && (
          <>
            <ContentItemTypeIcon contentItem={contentItem} />
            <TextSubTypeHeading subType={contentItem.subType} />
            <div
              title={
                contentItem.id &&
                `ID: ${contentItem.id}, Slug: ${contentItem.slug}`
              }
            >
              {contentItem.title || "New"}
            </div>
            <TitleSubHeading>{contentItem.internalName}</TitleSubHeading>
          </>
        )}
      </TitleHeading>
    </TitleWrapper>
  );
}
