// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import DeleteIcon from "components/icon/DeleteIcon";

interface Props {
  onDelete: (e: any) => void;
}

export default function DeleteCol(props: Props) {
  const { onDelete } = props;

  return (
    <div
      className="cursor-pointer hover:text-red-700"
      onClick={onDelete}
      title="Removes this item from the list"
    >
      <DeleteIcon
        className="fill-current hover:text-red-700 mr-2"
        style={{ width: "16px", height: "16px" }}
      />
    </div>
  );
}
