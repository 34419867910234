import { NeutralButton, DeleteButton } from "components/ui";
import SimpleModal from "components/SimpleModal";
import ApiErrors from "components/ApiErrors";
import { ApiResponse } from "types/api";
import { ThemeArea } from "types/theme";

interface Props {
  deleting?: boolean;
  deleteLabel?: string;
  deletionResponse?: ApiResponse<any>;
  onClose?: () => void;
  onDelete?: () => void;
  prompt?: string;
  themeArea: ThemeArea;
}

export default function ConfirmDelete(props: Props) {
  const { deleting, deletionResponse, onClose, onDelete, prompt } = props;

  return (
    <SimpleModal
      isOpen={Boolean(deleting)}
      onClose={onClose}
      themeArea={props.themeArea ?? "admin"}
    >
      <p className="p">
        {prompt || "Are you sure you want to delete this item?"}
      </p>

      <ApiErrors error={deletionResponse} />

      <div className="flex gap-4 justify-center mt-8">
        <NeutralButton onClick={onClose}>Cancel</NeutralButton>
        <DeleteButton onClick={onDelete} withIcon>
          {props.deleteLabel || "Delete"}
        </DeleteButton>
      </div>
    </SimpleModal>
  );
}
