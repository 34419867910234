import clsx from "clsx";

interface Props {
  bgColorClass?: string;
  children: React.ReactNode;
  className?: string;
  size?: "sm" | "md";
  style?: any;
}

export default function CountBadge(props: Props) {
  const { children, className, size, style } = props;
  const bgColorClass = props.bgColorClass || "bg-new-red-500";
  let minW = "min-w-[16px]";
  let px = "px-1";
  let py = "";
  let fontSize = "text-2xs";

  if (size === "md") {
    minW = "min-w-[26px]";
    px = "px-1";
    py = "py-[2px]";
    fontSize = "text-sm";
  }

  return (
    <div
      className={clsx(
        "inline-block",
        minW,
        "rounded-full",
        px,
        py,
        bgColorClass,
        fontSize,
        "text-white",
        "text-center",
        "font-montserrat tracking-[0px]",
        className
      )}
      style={style || {}}
    >
      {children}
    </div>
  );
}
