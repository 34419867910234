// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ColumnLabel, FormRow, TextInput } from "components/form";
import { UseFormReturn } from "react-hook-form";
import types from "types/field";

export interface DynamicOtherTextFieldProps {
  component: types.Element;
  fieldRow?: types.FieldRow;
  questionId: string;
  hookForm: UseFormReturn<any>;
}

export function DynamicOtherTextField(
  props: DynamicOtherTextFieldProps
): JSX.Element {
  const {
    fieldRow,
    hookForm: { register },
    questionId,
  } = props;
  const label = fieldRow?.label;

  return (
    <FormRow className="flex gap-4 items-center">
      <ColumnLabel
        style={{ width: "6em", minWidth: "6em", maxWidth: "6em" }}
        className="flex-grow"
      >
        {label?.value || "Other"}
      </ColumnLabel>
      <TextInput
        register={register && register(`answers.${questionId}.otherValue`)}
      />
    </FormRow>
  );
}
