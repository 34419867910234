import { makeObservable, observable, runInAction } from "mobx";

interface ModalProps {
  onClose?: () => void;
}

// Manages state for the Alert component, so that it can be used from any point within the component hierarchy.
export default class AlertStore {
  isOpen: boolean;
  children: React.ReactNode;
  modalProps: ModalProps;

  constructor() {
    this.isOpen = false;
    this.children = null;
    this.modalProps = {};
    makeObservable(this, {
      isOpen: observable,
      children: observable,
      modalProps: observable,
    });
  }

  open(children: React.ReactNode, opts?: { modalProps?: ModalProps }) {
    runInAction(() => {
      this.isOpen = true;
      this.children = children;
      this.modalProps = opts?.modalProps || {};
    });
  }

  close() {
    runInAction(() => {
      this.isOpen = false;
      this.children = null;
      this.modalProps = {};
    });
  }
}
