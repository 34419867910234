// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { TextInput } from "components/form";
import BasicForm from "components/form/BasicForm";
import FieldEditRow from "components/form/FieldEditRow";
import { useMpForm } from "components/form/helpers";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { ApiResponse } from "types/api";

interface Props {
  autoFocus?: boolean;
  errorMessages?: { [index: string]: string };
  label?: string;
  name: string;
  initialValue?: string | null;
  initialValues?: { [index: string]: any };
  onSubmit?: (values: any) => Promise<ApiResponse<any> | null>;
  renderEffect?: () => void;
  renderInput?: (props: any, hookForm: UseFormReturn<any>) => React.ReactNode;
  registerProps?: any;
}

export default function SingleFieldForm(props: Props) {
  const { autoFocus, errorMessages, initialValue, label, name, onSubmit } =
    props;
  let initialValues = { [name]: initialValue };
  if (props.initialValues) {
    initialValues = { ...initialValues, ...props.initialValues };
  }

  const usingHookForm = useMpForm({
    hookFormProps: {
      mode: "all",
      defaultValues: { [name]: "" },
    },
    initialValues,
    onSubmit,
  });

  const { hookForm } = usingHookForm;
  const { register } = hookForm;
  const registerProps = props.registerProps;
  const inputProps = { register: register(name, registerProps) };

  useEffect(() => {
    if (autoFocus) {
      hookForm.setFocus(name);
    }
  }, [autoFocus]);

  return (
    <BasicForm usingHookForm={usingHookForm}>
      <FieldEditRow
        label={label}
        name={name}
        hookForm={hookForm}
        errorMessages={errorMessages}
      >
        {(props.renderInput && props.renderInput(inputProps, hookForm)) || (
          <TextInput {...inputProps} />
        )}
      </FieldEditRow>
    </BasicForm>
  );
}
