// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import useSortableList from "lib/sortableList";
import React, { useState } from "react";
import { ContentItem, ContentItemExtended } from "types/content";
import SelectTextItem from "./SelectTextItem";
import AddItem from "./listItem/AddItem";
import TextListItem from "./TextListItem";

interface Props {
  contentItem: ContentItemExtended;
  onAddExistingChild?: (item: ContentItem) => Promise<boolean>;
  onItemRemoveRequested?: (item: ContentItem) => void;
  onItemSelected?: (item: ContentItem) => void;
  onNewChildClick?: (v: "new" | "existing") => void;
  onReorder?: (ids: string[]) => void;
}

export default function TextItemList(props: Props) {
  const { contentItem, onItemSelected } = props;

  const [addingExistingChild, setAddingExistingChild]: [
    boolean,
    (v: boolean) => void
  ] = useState(false as boolean);

  const sortable = useSortableList({ onSortComplete });

  function onSortComplete(indices: number[]) {
    const newIds = indices.map(
      (i) => (contentItem.children as { id: string }[])[i].id
    );

    props.onReorder && props.onReorder(newIds);
  }

  function onItemClick(item: ContentItem) {
    onItemSelected && onItemSelected(item);
  }

  function onItemRemoveRequested(item: ContentItem) {
    props.onItemRemoveRequested && props.onItemRemoveRequested(item);
  }

  function onNewClick(addingWhich: "new" | "existing") {
    if (addingWhich === "existing") {
      setAddingExistingChild(true);
    } else if (addingWhich === "new") {
      props.onNewChildClick && props.onNewChildClick(addingWhich);
    }
  }

  return (
    <div id="TextItemList">
      <div id="contentItems">
        {contentItem.children.map((item) => (
          <TextListItem
            contentItem={item}
            key={item.id}
            onDelete={onItemRemoveRequested}
            sortable={sortable}
            onClick={onItemClick}
          />
        ))}
      </div>

      <AddItem label="Add new" onClick={onNewClick} allowAddExisting />

      <SelectTextItem
        isOpen={addingExistingChild}
        onClose={() => setAddingExistingChild(false)}
        onItemSelected={props.onAddExistingChild}
        queryParams={{
          subType: contentItem.subType,
          parentId: null,
          plurality: "item",
        }}
      />
    </div>
  );
}
