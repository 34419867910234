import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { buildMutationResult } from "lib/gf-api/gql";
import { useEffect, useState } from "react";
import { NormalizedMutationResponse } from "types/api";
import { AshError, UseMutation } from "types/ash";

export function useAshMutation<T = any>(gql: DocumentNode): UseMutation<T> {
  const [apolloMutation, mutationInfo] = useMutation<T>(gql);
  const { error: serverError, loading, data } = mutationInfo;
  const [errors, setErrors] = useState<AshError[] | undefined>(undefined);

  function reset() {
    mutationInfo.reset();
    setErrors(undefined);
  }

  async function mutation<T>(
    options:
      | MutationFunctionOptions<
          T,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
    mutationName: string
  ): Promise<NormalizedMutationResponse<T>> {
    const result = await apolloMutation(options as any);
    return buildMutationResult<T>(result, mutationName);
  }

  useEffect(() => {
    if (data) {
      const key = Object.keys(data)[0];
      const result = (data as any)[key];
      if (result?.errors) {
        setErrors(result.errors);
      }
    }
  }, [data]);

  return [mutation, { serverError, errors, loading, data, reset }];
}
