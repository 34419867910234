// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ReactModal from "react-modal";
import { ApiErrorState } from "types/api";
import Button from "components/Button";

interface Props {
  apiError: ApiErrorState;
  onClose?: () => void;
}

export default function GeneralApiError(props: Props) {
  const { apiError, onClose } = props;

  function close() {
    onClose && onClose();
  }

  return (
    <ReactModal isOpen={Boolean(apiError)} onRequestClose={close}>
      {apiError && (
        <>
          <p className="p mt-0">An error occurred.</p>
          <p className="p">{apiError.explanation}</p>
          <div>
            <Button onClick={close}>Ok</Button>
          </div>
        </>
      )}
    </ReactModal>
  );
}
