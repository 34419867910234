interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function HelpText(props: Props) {
  const { children, className } = props;

  return <span className={`text-gray-darker ${className}`}>{children}</span>;
}
