import SecondaryNavBar from "components/layout/SecondaryNavbar";
import { buildClassName } from "lib/string";

interface Props {
  className?: string;
  parentTag?: string;
}

export default function SecondaryNavigation(props: Props) {
  let { parentTag, className, ...props2 } = props;
  className = buildClassName(parentTag, className);
  props2 = { ...props2, className };
  return <SecondaryNavBar {...props2} />;
}
