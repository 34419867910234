// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import SelectionArea from "./SelectionArea";
import {
  useContentEditStore,
  useContentStore,
  useMember,
} from "lib/gf-app-context";
import { memberHasRoleAccess } from "lib/auth";
import { observer } from "mobx-react-lite";
import {
  ContentBaseType,
  ContentItemParams,
  DashStyle,
  NavigationState,
} from "types/content";
import clsx from "clsx";
import React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  componentType?: React.ElementType;
  contentId?: string | null;
  dashStyle?: DashStyle;
  editById?: { id: string; baseType: ContentBaseType };
  editBySlug?: { slug: string; baseType: ContentBaseType };
  navName?: "primaryNav" | "secondaryNav" | "sideNav";
  navState?: NavigationState;
  style?: any;
}

function EditableSection(props: Props) {
  const {
    children,
    contentId,
    dashStyle,
    editById,
    editBySlug,
    navState,
    navName,
    style,
  } = props;
  const Component = props.componentType || ((props: any) => <div {...props} />);
  const user = useMember();
  const editingEnabled = user && memberHasRoleAccess(user.account, "content");
  const contentEditStore = useContentEditStore();
  const contentStore = useContentStore();
  const referencePresent = Boolean(
    contentId || navName || navState || editById || editBySlug
  );
  const selecting =
    referencePresent &&
    editingEnabled &&
    contentEditStore?.status === "selecting";

  function onEditArea() {
    if (contentId) {
      contentEditStore?.edit({ baseType: "text", id: contentId });
    } else if (navName && contentStore.navigation) {
      const item = contentStore.navigation[navName];
      contentEditStore?.edit({ baseType: "nav", id: item.id }, "edit");
    } else if (editById) {
      const { baseType, id } = editById;
      let item;
      if (baseType === "nav") {
        item = contentStore?.navItems?.find((item) => item.id === id);
      } else if (baseType === "text") {
        item = contentStore?.textItems?.find((item) => item.id === id);
      }
      if (item) {
        contentEditStore?.edit(
          { baseType, id },
          item.plurality === "item" ? "edit" : "list"
        );
      }
    } else if (editBySlug) {
      const { slug, baseType } = editBySlug;
      let initialParams: Partial<ContentItemParams> = { slug };
      let item;
      if (baseType === "nav") {
        item = contentStore?.navItems?.find((item) => item.slug === slug);
        initialParams = {
          ...initialParams,
          baseType: "nav",
          plurality: "list",
        };
      } else if (baseType === "text") {
        item = contentStore?.textItems?.find((item) => item.slug === slug);
      }

      if (item) {
        contentEditStore?.edit(
          { baseType, id: item.id },
          item.plurality === "item" ? "edit" : "list"
        );
      } else {
        const navState: NavigationState = {
          baseType,
          baseTypeEditMode: "add",
          initialParams,
        };
        contentEditStore?.pushStack(navState);
      }
    } else if (navState) {
      if (!navState.baseType) throw new Error("Missing initialParams.baseType");
      if (!navState.initialParams?.subType)
        throw new Error("Missing initialParams.subType");

      contentEditStore?.pushStack(navState);
    } else {
      throw new Error(
        "Missing contentId or navSlug or initialParams in EditableSection call"
      );
    }
  }

  if (!editingEnabled) {
    if (props.className || style) {
      return (
        <div className={props.className} style={style}>
          {children}
        </div>
      );
    } else {
      return <>{children}</>;
    }
  }

  return (
    <Component
      className={clsx("EditableSection relative", props.className)}
      style={style}
    >
      {selecting && (
        <SelectionArea onClick={onEditArea} dashStyle={dashStyle} />
      )}
      {children}
    </Component>
  );
}

export default observer(EditableSection);
