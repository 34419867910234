// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { marked } from "marked";
import { RichTextFormat } from "types/util";
const { convert: externalHtmlToText } = require("html-to-text");

interface PadProps {
  size?: number;
  rightSide?: boolean;
}

export function pad(number: number | string, opts?: PadProps): string {
  number = `${number}`;
  opts = opts || {};
  const size = opts.size || 2;
  if (number.length < size) {
    const padded = opts.rightSide ? `${number}0` : `0${number}`;
    return pad(padded, { ...opts, size: size - 1 });
  }
  return number;
}

export function padMoney(origNumber: number | string, prefix = ""): string {
  let number: number;
  if (typeof origNumber === "string") {
    number = parseFloat(origNumber);
  } else {
    number = origNumber;
  }
  const asCents = Math.round(number * 100);
  const cents = Math.abs(asCents) % 100;
  const dollars = Math.floor(asCents / 100);
  return `${prefix}${dollars}.${`${cents}`.padEnd(2, "0")}`;
}

export function formatDollarAmount(amount: string | number) {
  if (typeof amount === "number") {
    amount = `${amount}`;
  }
  const amountString = formatMaybeDecimalAmount(amount);
  return `$${amountString}`;
}

export function round(amount: number, places: number) {
  places = places || 0;
  const factor = Math.pow(10, places);
  return Math.round(amount * factor) / factor;
}

export function formatMaybeDecimalAmount(amount: string) {
  if (!amount) return "0";
  const float = parseFloat(amount);
  const int = parseInt(amount);
  if (float > int) {
    const fractional = `${round(float - int, 2)}`
      .replace(/^\d+\./, "")
      .padEnd(2, "0")
      .substring(0, 2);

    let whole = numberWithCommas(`${int}`);
    return `${whole}.${fractional}`;
  } else {
    return numberWithCommas(`${int}`);
  }
}

export function numberWithCommas(number: string) {
  if (!number) return "";
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatPhone(value: string | undefined | null) {
  if (!value) return "";
  value = value.replace(/[^\d]/, "");

  return [value.slice(-10, -7), value.slice(-7, -4), value.slice(-4)]
    .filter((part) => part)
    .join("-");
}

export function truncate(string: string, length: number) {
  if (string.length <= length) {
    return string;
  }

  return string.substring(0, length - 1) + "…";
}

export function htmlToText(html: string): string {
  //remove code brakes and tabs
  html = html.replace(/\n/g, "");
  html = html.replace(/\t/g, "");

  //keep html brakes and tabs
  html = html.replace(/<\/td>/g, "\t");
  html = html.replace(/<\/table>/g, "\n");
  html = html.replace(/<\/tr>/g, "\n");
  html = html.replace(/<\/p>/g, "\n\n");
  html = html.replace(/<\/div>/g, "\n");
  html = html.replace(/<\/h>/g, "\n");
  html = html.replace(/<br>/g, "\n");
  html = html.replace(/<br( )*\/>/g, "\n");

  //parse html into text
  const dom = new DOMParser().parseFromString(
    "<!doctype html><body>" + html,
    "text/html"
  );
  return dom.body.textContent || "";
}

export function convertDescriptionToHtml(text: string, format: RichTextFormat) {
  if (format === "html") {
    return text;
  } else {
    return convertToHtml(text, format);
  }
}

export function convertToHtml(
  source: string,
  sourceFormat: RichTextFormat
): string {
  if (sourceFormat === "html") {
    return source;
  } else if (sourceFormat === "markdown") {
    return marked(source);
  } else if (sourceFormat === "plain") {
    return marked(source);
  }
  throw new Error(`Unknown format: ${sourceFormat}`);
}

export function convertToPlain(
  source: string,
  sourceFormat: RichTextFormat
): string {
  if (sourceFormat === "plain") {
    return source;
  } else if (sourceFormat === "markdown") {
    const html = convertToHtml(source, "markdown");
    return convertToPlain(html, "html");
  } else if (sourceFormat === "html") {
    return externalHtmlToText(source);
  }
  throw new Error(`Unknown format: ${sourceFormat}`);
}

export function convertToMarkdown(
  source: string,
  sourceFormat: RichTextFormat
): string {
  if (sourceFormat === "markdown") {
    return source;
  } else if (sourceFormat === "plain") {
    return source;
  } else if (sourceFormat === "html") {
    return htmlToMarkdown(source);
  }

  throw new Error(`Unknown format: ${sourceFormat}`);
}

export function htmlToMarkdown(html: string) {
  const dom = new DOMParser().parseFromString(
    "<!doctype html><body>" + html,
    "text/html"
  );
  const nodes = [...(dom.childNodes as any)] as Element[];
  return nodes.map((node) => domToMarkdown(node as Element)).join("");
}

function domToMarkdown(dom: Element): string {
  const digInNames = ["HTML", "BODY"];
  const doubleLineNames = ["H1", "H2", "H3", "H4", "H5", "H6", "P"];
  let markdown = "";

  if (dom.nodeName === "LI") {
    const text = dom.textContent;
    markdown = `* ${text}\n`;
  } else if (dom.nodeName === "UL") {
    markdown = [...(dom.children as any)]
      .map((el) => domToMarkdown(el))
      .join("");
    markdown = markdown + "\n";
  } else if (dom.nodeName === "BR") {
    markdown = "<br>\n";
  } else if (digInNames.includes(dom.nodeName)) {
    markdown = [...(dom.childNodes as any)]
      .map((el) => domToMarkdown(el))
      .join("");
  } else if (doubleLineNames.includes(dom.nodeName)) {
    markdown = [...(dom.childNodes as any)]
      .map((el) => domToMarkdown(el))
      .join("");

    if (dom.nodeName === "H1") {
      markdown = `# ${markdown}`;
    } else if (dom.nodeName === "H2") {
      markdown = `## ${markdown}`;
    } else if (dom.nodeName === "H3") {
      markdown = `### ${markdown}`;
    } else if (dom.nodeName === "H4") {
      markdown = `#### ${markdown}`;
    } else if (dom.nodeName === "H5") {
      markdown = `##### ${markdown}`;
    } else if (dom.nodeName === "H6") {
      markdown = `###### ${markdown}`;
    }
    markdown = markdown + "\n\n";
  } else {
    markdown = (dom.textContent || "").replace(/[\r\n]+/, "");
  }

  return markdown;
}

export function capitalize(str: string) {
  const ch = str[0];
  if (!ch) return str;
  const rest = str.substring(1, str.length);
  return ch.toLocaleUpperCase() + rest;
}

export function parseBoolean(value: any) {
  if (typeof value === "string") {
    return value === "true";
  } else if (typeof value === "boolean") {
    return value;
  }
  return value;
}

export function parseNumber(value: any) {
  if (typeof value === "number" && !isNaN(value)) {
    return value;
  } else if (typeof value === "string") {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return undefined;
    } else {
      return number;
    }
  } else {
    return undefined;
  }
}

export function cleanupMarkdown(markdown: string) {
  return markdown
    .replace(/\\\./g, ".")
    .replace(/\\!/g, "!")
    .replace(/\\-/g, "-");
}

export function buildClassName(...args: any[]) {
  const className = args.filter((arg) => !!arg).join(" ");
  if (className === "") {
    return undefined;
  }
  return className;
}

export function encodeUriComponentLight(value: string) {
  return value
    .replace(/&/g, "%26")
    .replace(/\?/g, "%3F")
    .replace(/=/g, "%3D")
    .replace(/ /g, "+");
}

export function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(
    /[018]/g,
    (cString: string) => {
      const c = parseInt(cString);
      return (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    }
  );
}
