import clsx from "clsx";

interface Props {
  className?: string;
  field?: React.ReactNode;
  label?: React.ReactNode;
}

export default function FieldOptionLayout(props: Props) {
  const { className, field, label } = props;

  return (
    <div
      className={clsx(
        "flex flex-row justify-between content-start gap-2",
        className
      )}
    >
      <div>{field}</div>
      <div className="flex-grow">{label}</div>
    </div>
  );
}
