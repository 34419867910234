import { makeAutoObservable, runInAction } from "mobx";

interface Props {
  navItemSlug?: string;
  navItemId?: string;
}

class HamburgerStore {
  navItemSlug?: string;
  navItemId?: string;
  isOpen = false;

  constructor(props?: Props) {
    this.navItemSlug = props?.navItemSlug;
    this.navItemId = props?.navItemId;
    makeAutoObservable(this);
  }

  open() {
    runInAction(() => {
      this.isOpen = true;
    });
  }

  close() {
    runInAction(() => {
      this.isOpen = false;
    });
  }

  toggle() {
    runInAction(() => {
      this.isOpen = !this.isOpen;
    });
  }

  setNavItemId(id: string) {
    runInAction(() => {
      this.navItemId = id;
    });
  }

  setNavItemSlug(slug: string) {
    runInAction(() => {
      this.navItemSlug = slug;
    });
  }
}

export default HamburgerStore;
