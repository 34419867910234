// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
}

export default function TitleHeading(props: Props) {
  const { children } = props;

  return (
    <h1
      className="h1 flex-grow flex flex-row gap-2 items-center whitespace-nowrap whitespace-nowrap truncate"
      style={{ marginBottom: 0 }}
    >
      {children}
    </h1>
  );
}
