// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { ContentItem } from "types/content";
import { UseSortableReturn } from "types/sortable";
import ContentItemTypeIcon from "./ContentItemTypeIcon";
import DeleteCol from "./listItem/DeleteCol";
import GripCol from "./listItem/GripCol";
import LabelCol from "./listItem/LabelCol";
import ListItemWrapper from "./listItem/ListItemWrapper";
import StatusIcon from "./StatusIcon";

interface Props {
  sortable?: UseSortableReturn;
  contentItem: ContentItem;
  onClick?: (contentItem: ContentItem) => void;
  onDelete?: (contentItem: ContentItem) => void;
}

export default function TextListItem(props: Props) {
  const { sortable, contentItem, onClick } = props;

  function onDelete(e: any) {
    e.preventDefault();
    e.stopPropagation();
    props.onDelete && props.onDelete(contentItem);
  }

  return (
    <ListItemWrapper
      id={`nav-item-${contentItem.id}`}
      title={`ID: ${contentItem.id}`}
      onClick={() => {
        onClick && onClick(contentItem);
      }}
      sortable={sortable}
    >
      <GripCol />
      <ContentItemTypeIcon contentItem={contentItem} />
      <StatusIcon contentItem={contentItem} />
      <LabelCol>
        <span className="inline-block" title={`ID: ${contentItem.id}`}>
          {contentItem.title}
        </span>
        {contentItem.internalName && (
          <span className="text-gray-dark inline-block truncate ml-2">
            - {contentItem.internalName}
          </span>
        )}
      </LabelCol>
      <DeleteCol onDelete={onDelete} />
    </ListItemWrapper>
  );
}
