// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { dayAndDate } from "lib/constants";
import { formatDate } from "lib/date";
import TimeWithOffset from "./TimeWithOffset";

interface Props {
  dateTime: Date | string;
  className?: string;
}

export default function DayDateTimeOffset(props: Props) {
  let dateTime = props.dateTime;
  if (typeof dateTime === "string") {
    dateTime = new Date(dateTime);
  }
  const thisYear = new Date().getFullYear();

  return (
    <div className={`flex ${props.className ? props.className : ""}`}>
      <span className="mr-4">
        {dateTime.getFullYear() === thisYear
          ? formatDate(dateTime, dayAndDate)
          : formatDate(dateTime, "EEEE, MMMM dd, yyyy")}
      </span>
      <TimeWithOffset dateTime={props.dateTime} />
    </div>
  );
}
