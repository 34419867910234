import LoginButton from "components/login/LoginButton";

export default function LoginRequired() {
  return (
    <div className="flex flex-col gap-y-8">
      <h1 className="h1">Login Required</h1>
      <p className="p">You must be logged in to view this page.</p>
      <div className="flex justify-center">
        <LoginButton />
      </div>
    </div>
  );
}
