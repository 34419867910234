// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import { ColumnLabel, FormRow, FieldColumn } from ".";

interface Props {
  children?: React.ReactNode;
  className?: string;
  fieldColumnProps?: any;
  label?: React.ReactNode;
  rowSpacing?: "small" | "medium" | "large";
  value?: string | React.ReactNode | null | undefined;
}

export default function ColumnStyleFieldRow(props: Props) {
  const { children, label, rowSpacing, value } = props;
  const className = clsx(props.className, "flex flex-row gap-2");
  const fieldColumnProps = props.fieldColumnProps || {};

  return (
    <FormRow className={className} columnStyle>
      {label && <ColumnLabel spacing={rowSpacing}>{label}</ColumnLabel>}
      <FieldColumn {...fieldColumnProps}>{children || value}</FieldColumn>
    </FormRow>
  );
}
