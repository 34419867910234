// Copyright © 2017 Moxley Data Systems - All Rights Reserved

export function documentOffset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    right: Math.floor(rect.right) + scrollLeft,
    bottom: Math.floor(rect.bottom) + scrollTop
  };
}

export function insertTextAtCursor(el: HTMLTextAreaElement, text: string) {
  const val = el.value;
  let endIndex;
  let range;
  const doc = el.ownerDocument as any;

  if (
    typeof el.selectionStart === "number" &&
    typeof el.selectionEnd === "number"
  ) {
    endIndex = el.selectionEnd;
    const value = val.slice(0, endIndex) + text + val.slice(endIndex);
    const selectionStart = (el.selectionEnd = endIndex + text.length);
    el.selectionStart = selectionStart;
    el.value = value;
  } else if (
    doc.selection !== "undefined" &&
    doc.selection !== undefined &&
    doc.selection.createRange
  ) {
    el.focus();
    range = doc.selection.createRange();
    range.collapse(false);
    range.text = text;
    range.select();
  }
}
