import Head from "next/head";
import { observer } from "mobx-react-lite";

import nextConfigFn from "next/config";
import { useGroupConfig } from "lib/gf-app-context";
import { pageTitle } from "lib/content";
import Script from "next/script";
import GoogleFonts from "./GoogleFonts";
// import HotJar from "./HotJar";

interface Props {
  title?: string;
}

function HtmlHead(props: Props) {
  const groupConfig = useGroupConfig();
  const { faviconUrl } = groupConfig;
  const config = nextConfigFn();
  const { squareSdkBaseUrl, release } = config.publicRuntimeConfig;
  const title = pageTitle(props.title, groupConfig);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="release" content={release} />
        <title>{title}</title>
        {faviconUrl && (
          <link rel="shortcut icon" type="image/png" href={faviconUrl} />
        )}
      </Head>
      <GoogleFonts groupConfig={groupConfig} />
      <Script type="text/javascript" src={`${squareSdkBaseUrl}/v1/square.js`} />
      <Script src="https://js.stripe.com/v3/" />
      {/* <HotJar /> */}
    </>
  );
}

export default observer(HtmlHead);
