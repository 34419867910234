import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { useLoginModalStore, useMember } from "lib/gf-app-context";

interface Props {
  introText?: string;
}

function LoginButton(props: Props) {
  const { introText } = props;
  const loginModalStore = useLoginModalStore();
  const user = useMember();

  if (user || !loginModalStore) {
    return null;
  }

  return (
    <>
      {introText && <div>{introText}</div>}
      <Button onClick={() => loginModalStore.open()}>Log in now</Button>
    </>
  );
}

export default observer(LoginButton);
