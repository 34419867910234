// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useRef } from "react";
import { UseSortableReturn } from "types/sortable";

interface Props {
  onSortComplete?: (
    indices: number[],
    ordered: [number, string][],
    name?: string
  ) => void;
  findGripElement?: (parentEl: HTMLElement) => HTMLElement | null;
  name?: string;
}

export default function useSortableList(props?: Props): UseSortableReturn {
  props = props || {};
  const { name, onSortComplete } = props;
  const dragIdRef = useRef(undefined as string | undefined);
  const parentIdRef = useRef(undefined as string | undefined);
  const indicesMap = useRef(new Map<string, number>());
  const draggingRef = useRef(false as boolean);
  const gripMouseDownRef = useRef(false as boolean);
  const disabled = useRef(false as boolean);

  function itemRef(el: HTMLElement) {
    if (!props?.findGripElement) return;
    if (el) {
      const gripEl = props.findGripElement(el);
      if (!gripEl) {
        throw new Error("Grip element not found");
      }
      gripEl.addEventListener("mousedown", () => {
        gripMouseDownRef.current = true;
      });
      gripEl.addEventListener("mouseup", () => {
        gripMouseDownRef.current = false;
      });
    }
  }

  function onDragStart(ev: React.DragEvent<HTMLElement>) {
    ev.dataTransfer.dropEffect = "move";
    const el = ev.target as HTMLElement;
    const id = el.id;
    if (!id) {
      console.log("onDragStart() ev.target", ev.target);
      throw new Error("Expect draggable element to have an id");
    }
    const parentEl = el.parentElement as HTMLElement;
    if (!parentEl.id) {
      throw new Error("Expected list container to have an id");
    }

    if (props?.findGripElement && !gripMouseDownRef.current) {
      disabled.current = true;
      return;
    }

    dragIdRef.current = id;
    parentIdRef.current = parentEl.id;
    tagIndices(ev.target as HTMLElement);
    draggingRef.current = true;
  }

  function tagIndices(childEl: HTMLElement) {
    indicesMap.current = new Map<string, number>();
    const parent = childEl.parentElement as HTMLElement;
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children[i] as HTMLElement;
      indicesMap.current.set(child.id, i);
    }
  }

  function onDragEnter(ev: React.DragEvent<HTMLElement>) {
    if (disabled.current) {
      return;
    }
    ev.preventDefault();
    const dragEl = document.getElementById(
      dragIdRef.current as string
    ) as HTMLElement;
    const el = findItemEl(ev.target as HTMLElement);
    if (!el) {
      console.info("error info", {
        dragId: dragIdRef.current,
        target: ev.target,
        name,
      });
      throw new Error("Could not find droppable element");
    }

    swapElements(dragEl, el);
  }

  function onDragEnd() {
    if (disabled.current) {
      disabled.current = false;
      return;
    }

    gripMouseDownRef.current = false;

    if (draggingRef.current) {
      draggingRef.current = false;
      // Swap drag element with the element that is now in the drag element's place
      const dragEl = getDragEl();
      const index = getIndex(dragEl);
      const originalIndex = indicesMap.current.get(dragEl.id) as number;
      if (index !== originalIndex) {
        moveElement(dragEl, originalIndex);
      }
    }
  }

  function getDragEl(): HTMLElement {
    const id = dragIdRef.current;
    if (!id) {
      throw new Error("Expected dragIdRef.current");
    }
    return document.getElementById(id) as HTMLElement;
  }

  function moveElement(el: HTMLElement, index: number) {
    const sibling = getElementAtIndex(index);
    const elIndex = getIndex(el) as number;
    if (index < elIndex) {
      // Insert the element before the index
      sibling.insertAdjacentElement("beforebegin", el);
    } else {
      // Insert the element after the index
      sibling.insertAdjacentElement("afterend", el);
    }
  }

  function getElementAtIndex(index: number): HTMLElement {
    const parent = getParentElement();
    return parent.children[index] as HTMLElement;
  }

  function getIndex(el: HTMLElement) {
    const parent = getParentElement();
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children[i];
      if (child.id === el.id) {
        return i;
      }
    }
    return null;
  }

  function getParentElement(): HTMLElement {
    const parentId = parentIdRef.current;
    if (!parentId) {
      throw new Error("expected parentIfRef.current");
    }
    const parent = document.getElementById(parentId);
    if (!parent) {
      throw new Error("Parent element not found for ID");
    }
    return parent;
  }

  function findItemEl(el: HTMLElement): HTMLElement | null {
    const parent = el.parentElement as HTMLElement;
    if (!parent) {
      return null;
    }
    if (parent.id === parentIdRef.current) {
      return el;
    } else {
      return findItemEl(parent);
    }
  }

  function onDragOver(ev: React.DragEvent<HTMLElement>) {
    if (disabled.current) return;
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "copy";
  }

  function swapElements(dragEl: HTMLElement, dropEl: HTMLElement) {
    const parent = dragEl.parentElement as HTMLElement;

    const tempNodes = [] as Element[];
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children.item(i) as HTMLElement;
      const childIndex = indicesMap.current.get(child.id);
      const dragIndex = indicesMap.current.get(dragEl.id);
      const dropIndex = indicesMap.current.get(dropEl.id);
      if (childIndex === dragIndex) {
        tempNodes.push(dropEl as Element);
      } else if (childIndex === dropIndex) {
        tempNodes.push(dragEl as Element);
      } else {
        tempNodes.push(child);
      }
    }

    (parent as any).replaceChildren(...tempNodes);
  }

  function onDrop(ev: React.DragEvent<HTMLElement>) {
    if (disabled.current) return;
    ev.preventDefault();
    draggingRef.current = false;
    const indices = [] as number[];
    const ordered = [] as [number, string][];
    const el = findItemEl(ev.target as HTMLElement);
    if (!el) {
      throw new Error("Could not find droppable element");
    }
    const parent = el.parentElement as HTMLElement;
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children[i] as HTMLElement;
      const index = indicesMap.current.get(child.id) as number;
      indices.push(index);
      ordered.push([i, child.id]);
    }
    onSortComplete && onSortComplete(indices, ordered, name);
  }

  return {
    ref: itemRef,
    onDragStart,
    onDragEnter,
    onDragOver,
    onDragEnd,
    onDrop,
    draggable: true,
  };
}
