import AddIcon from "components/icon/AddIcon";
import SimpleModal from "components/SimpleModal";
import { useState } from "react";
import ListItemWrapper from "./ListItemWrapper";
import clsx from "clsx";
import HollowButton from "components/admin/ui/HollowButton";

interface Props {
  className?: string;
  onClick?: (adding: "new" | "existing") => void;
  label?: string;
  allowAddExisting?: boolean;
}

export default function AddItem(props: Props) {
  const { allowAddExisting, label } = props;
  const [showChoices, setShowChoices]: [boolean, (v: boolean) => void] =
    useState(false as boolean);

  function onClick() {
    if (allowAddExisting) {
      setShowChoices(true);
    } else {
      doChoice("new");
    }
  }

  function doChoice(adding: "new" | "existing") {
    props.onClick && props.onClick(adding);
    setShowChoices(false);
  }

  function choices() {
    return (
      <SimpleModal
        isOpen={showChoices}
        onClose={() => setShowChoices(false)}
        themeArea="admin"
      >
        <div className="flex gap-2 justify-center">
          <HollowButton onClick={() => doChoice("new")} colorStyle="primary">
            Add new item
          </HollowButton>
          <HollowButton
            onClick={() => doChoice("existing")}
            colorStyle="primary"
          >
            Add existing item
          </HollowButton>
        </div>
      </SimpleModal>
    );
  }

  return (
    <>
      <ListItemWrapper
        className={clsx("text-gray-dark cursor-pointer", props.className)}
        style={{ height: "1.75em" }}
        onClick={onClick}
      >
        <AddIcon
          className="fill-current mx-2"
          style={{ width: "16px", height: "16px" }}
        />
        {label && <div>{label}</div>}
      </ListItemWrapper>
      {choices()}
    </>
  );
}
