// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}

export default function YellowCircleIcon(props: Props) {
  const { className, style } = props;
  const otherProps = {} as any;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
      className={className}
      style={style}
      title="Yellow light"
      {...otherProps}
    >
      <defs>
        <linearGradient
          id="BTODxJqb_kNzKWyYy_kOFa"
          x1="-792"
          x2="-792"
          y1="6"
          y2="42"
          gradientTransform="translate(816)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff082" />
          <stop offset="1" stopColor="#e5cd14" />
        </linearGradient>
        <radialGradient
          id="BTODxJqb_kNzKWyYy_kOFb"
          cx="-792"
          cy="24"
          r="18"
          gradientTransform="translate(816)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffef75" stopOpacity="0" />
          <stop offset=".392" stopColor="#feee74" stopOpacity=".009" />
          <stop offset=".533" stopColor="#fdec70" stopOpacity=".044" />
          <stop offset=".633" stopColor="#f9e96a" stopOpacity=".103" />
          <stop offset=".715" stopColor="#f4e360" stopOpacity=".188" />
          <stop offset=".784" stopColor="#eedc54" stopOpacity=".299" />
          <stop offset=".846" stopColor="#e7d445" stopOpacity=".435" />
          <stop offset=".902" stopColor="#ddca33" stopOpacity=".598" />
          <stop offset=".952" stopColor="#d3bf1e" stopOpacity=".781" />
          <stop offset=".997" stopColor="#c8b207" stopOpacity=".987" />
          <stop offset="1" stopColor="#c7b106" />
        </radialGradient>
      </defs>
      <circle cx="24" cy="24" r="18" fill="url(#BTODxJqb_kNzKWyYy_kOFa)" />
      <circle cx="24" cy="24" r="18" fill="url(#BTODxJqb_kNzKWyYy_kOFb)" />
    </svg>
  );
}
