// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { UseSortableReturn } from "types/sortable";

interface Props {
  sortable?: UseSortableReturn | null;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onClick?: (e: any) => void;
  style?: any;
  title?: string;
}

export default function ListItem(props: Props) {
  const { sortable, children, id, onClick, style, title } = props;
  let { className } = props;
  if (onClick) {
    className = `${className} pointer-cursor`;
  }

  return (
    <div
      className={className || ""}
      id={id}
      draggable={!!sortable}
      onClick={onClick}
      {...sortable}
      style={style}
      title={title}
    >
      {children}
    </div>
  );
}
