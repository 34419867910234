// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  children: React.ReactNode;
}

export default function TitleWrapper(props: Props) {
  const { children } = props;

  return (
    <div className="flex justify-between items-center mb-4">{children}</div>
  );
}
