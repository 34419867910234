import { Me, MemberStatus } from "types/member";
import ActiveMemberRequired from "./ActiveMemberRequired";
import RegistrationRequired from "./RegistrationRequired";
import LoginRequired from "./LoginRequired";

interface Props {
  emailRequired?: boolean;
  memberStatusRequired?: MemberStatus;
  member?: Me | null;
}

export default function disallowedContent(props?: Props) {
  const { emailRequired, memberStatusRequired, member } = props || {};

  let content: React.ReactNode = null;

  if (memberStatusRequired && !member) {
    content = <LoginRequired />;
  } else if (
    memberStatusRequired === "ACTIVE" &&
    member?.account?.status === "INACTIVE"
  ) {
    content = <ActiveMemberRequired />;
  } else if (
    memberStatusRequired === "ACTIVE" &&
    member?.account?.status &&
    member.account.status === "NON_MEMBER"
  ) {
    content = <div>Not allowed.</div>;
  } else if (
    memberStatusRequired === "INACTIVE" &&
    member?.account?.status &&
    !["INACTIVE", "ACTIVE"].includes(member.account.status ?? "")
  ) {
    content = <div>Not allowed.</div>;
  } else if (emailRequired && member && !member.account.email) {
    content = <RegistrationRequired user={member} />;
  }

  return content;
}
