import ArrowIcon from "components/icon/ArrowIcon";

interface Props {
  expanded: boolean;
  onChange?: (v: boolean) => void;
}

export default function ExpandArrow(props: Props) {
  const { expanded, onChange } = props;

  return (
    <a
      onClick={() => onChange && onChange(!expanded)}
      className="text-gray hover:text-spp-violet-lightest cursor-pointer"
      style={{ marginTop: "2px" }}
    >
      <ArrowIcon
        className="fill-current"
        style={{
          width: "20px",
          height: "20px",
          transform: `rotate(${expanded ? 90 : 0}deg)`,
        }}
      />
    </a>
  );
}
