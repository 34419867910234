import { makeObservable, observable, runInAction } from "mobx";
import { claimsAreValid, decodeJWT } from "lib/jwt";
import { JwtClaims } from "types/auth";
import { cookieName } from "lib/constants";

export default class JwtStore {
  jwtValue: string | null;
  validClaims: null | JwtClaims;

  constructor(jwt: string | undefined) {
    this.jwtValue = null;
    this.validClaims = null;
    this.unprotectedSetJwt(jwt);

    makeObservable(this, {
      jwtValue: observable,
      validClaims: observable,
    });
  }

  unprotectedSetJwt(jwt: string | null | undefined) {
    this.jwtValue = jwt || null;
    const claims = (jwt && decodeJWT(jwt)) || null;
    if (claimsAreValid(claims)) {
      this.validClaims = claims;
    } else {
      this.validClaims = null;
    }
  }

  get memberJwt() {
    const claims = this.validClaims;
    return claims && ["members", "linked"].includes(claims.aud)
      ? this.jwtValue
      : null;
  }

  get linkedAccountJwt() {
    const claims = this.validClaims;
    return claims && claims.aud === "linked" ? this.jwtValue : null;
  }

  get passwordResetJwt() {
    const claims = this.validClaims;
    return claims && claims.aud === "pwd-reset" ? this.jwtValue : null;
  }

  get validJwt() {
    if (!this.validClaims || this.isExpired()) {
      this.clearJwt();
      return null;
    }
    return this.passwordResetJwt || this.memberJwt || this.linkedAccountJwt;
  }

  isExpired() {
    if (!this.validClaims) {
      return false;
    }
    const exp = this.validClaims.exp;
    // Check if the token is expired
    return exp && Date.now() / 1000 > exp;
  }

  clearJwt() {
    if (this.validClaims && this.isExpired()) {
      document.cookie = cookieName + "=" + ";path=/;Max-Age=-99999999;";
    }
    runInAction(() => {
      this.jwtValue = null;
      this.validClaims = null;
    });
  }

  setJwt(jwt: string) {
    runInAction(() => {
      this.unprotectedSetJwt(jwt);
    });
  }
}
