import { ApiCall, ApiResponse, CreatedSession, TokenResponse } from "types/api";
import {
  fetchAndLog,
  handleNonOkResponse,
  httpStatusCategory,
  gfApiHeaders,
} from "./api-util";
import { graphqlSingleCall } from "./gql";

const tokenReturnFields = ["token"];

export async function confirmPasswordEmailToken(
  call: ApiCall,
  variables: { token: string }
): Promise<ApiResponse<TokenResponse>> {
  return graphqlSingleCall<TokenResponse>({
    call,
    opName: "ConfirmPasswordToken",
    opType: "mutation",
    query: {
      argTypes: { token: "String!" },
      variables,
      qName: "confirmPasswordToken",
      returnNames: tokenReturnFields,
    },
  });
}

export async function createSession(
  call: ApiCall,
  params: {
    email?: string;
    password?: string;
    authCode?: string;
  }
): Promise<ApiResponse<CreatedSession>> {
  const response = await fetchAndLog(call.baseUrl + "/sessions", {
    body: JSON.stringify(params),
    headers: gfApiHeaders(call),
    method: "POST",
    credentials: "include",
  });

  const status = httpStatusCategory(response.status);
  if (status === "ok") {
    const data = (await response.json()) as CreatedSession;

    return {
      error: false,
      data,
    };
  } else {
    return handleNonOkResponse(response);
  }
}
