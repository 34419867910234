// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useState } from "react";

const defaultThrottlePeriod = 500;

export type ThrottleState = {
  start: number | undefined;
  timeoutId: NodeJS.Timeout | undefined;
  throttlePeriod?: number;
};

export type SetThrottleState = (state: ThrottleState) => void;

export function useThrottleState(): [ThrottleState, SetThrottleState] {
  return useState({
    start: undefined,
    timeoutId: undefined
  } as ThrottleState);
}

export default function throttled(
  callback: () => void,
  throttleState: ThrottleState,
  setThrottleState: SetThrottleState
) {
  const { timeoutId, start } = throttleState;
  const throttlePeriod = throttleState.throttlePeriod || defaultThrottlePeriod;

  if (start) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const now = new Date().valueOf();
    const timeRemaining = throttlePeriod - (now - start);
    const newTimeoutId = setTimeout(() => {
      callback();
      setThrottleState({ start: undefined, timeoutId: undefined });
    }, timeRemaining);
    setThrottleState({ ...throttleState, timeoutId: newTimeoutId });
    return;
  }

  callback();
  setThrottleState({ ...throttleState, start: new Date().valueOf() });
}
