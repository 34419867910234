interface Props {
  style?: any;
  className?: string;
}

export default function HamburgerMenuIcon(props: Props) {
  const { className, style } = props;

  return (
    <svg
      width="74"
      height="51"
      viewBox="0 0 74 51"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <rect width="74" height="9" fill="current" />
      <rect y="21" width="74" height="9" fill="current" />
      <rect y="42" width="74" height="9" fill="current" />
    </svg>
  );
}
