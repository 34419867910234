// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import dig from "lib/dig";
import { DeepMap, FieldError, UseFormReturn } from "react-hook-form";
import { TextFieldCommon } from "types/field";
import SimpleFieldError from "./SimpleFieldError";

interface Props {
  className?: string;
  errors: DeepMap<any, FieldError>;
  field?: any;
  hookForm?: UseFormReturn<any>;
  messages?: { [index: string]: string };
  name: string;
  validationConstrains?: any;
}

export default function ErrorMessage(props: Props) {
  const { errors, hookForm, name, messages } = props;
  const validationConstrains = props.validationConstrains || {};
  const error = dig(errors, name);

  if (!error || !error.type) return null;
  if (error.type === "itemRequired") return null;

  let message;

  if (error.message) {
    message = error.message;
  } else if (messages && messages[error.type]) {
    message = messages[error.type];
  } else if (error.type === "itemRequired") {
    message = "required";
  } else if (
    error.type === "maxLength" &&
    hookForm &&
    props.field &&
    typeof props.field.maxLength === "number"
  ) {
    const field = props.field as TextFieldCommon;
    const value = hookForm.watch(name);
    if (typeof value === "string") {
      message = `Maximum number of characters is ${field.maxLength}. Current length is ${value.length}.`;
    }
  } else if (error.type === "max") {
    if (validationConstrains.max) {
      message = `Maximum value is ${validationConstrains.max}`;
    } else {
      message = "Maximum value exceeded";
    }
  } else if (error.type === "min") {
    if (validationConstrains.min) {
      message = `Minimum value is ${validationConstrains.min}`;
    } else {
      message = "Minimum value not met";
    }
  } else {
    message = `${error.type}`;
  }

  return <SimpleFieldError className={props.className} message={message} />;
}
