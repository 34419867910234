import { textSubTypeTitle } from "lib/content";
import { ContentPlurality } from "types/content";

interface Props {
  plurality?: ContentPlurality;
  subType: string | undefined | null;
}

export default function TextSubTypeHeading(props: Props) {
  const { plurality, subType } = props;
  const text = textSubTypeTitle(subType);

  return (
    <div className="text-gray-dark">
      {text}
      {plurality === "list" ? "List" : null}:
    </div>
  );
}
