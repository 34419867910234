// Copyright © 2017 Moxley Data Systems - All Rights Reserved

interface Props {
  className?: string;
  style?: any;
}

export default function QuestionMark(props: Props) {
  const { className, style } = props;
  const otherProps = {} as any;

  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      className={className}
      style={style}
      title="Question mark"
      {...otherProps}
    >
      <path d="M 12.298828 2.0078125 C 9.2536504 1.8592539 6.6687031 3.9932031 6.1113281 6.8457031 C 5.9933281 7.4477031 6.4914687 8 7.1054688 8 C 7.5894687 8 7.9859375 7.6497813 8.0859375 7.1757812 C 8.5179375 5.1197812 10.534922 3.6391719 12.794922 4.0761719 C 14.716922 4.4481719 16.103141 6.2667031 15.994141 8.2207031 C 15.908141 9.7697031 14.910672 10.751859 13.763672 11.880859 C 12.712672 12.915859 11.548484 14.061281 11.146484 15.738281 C 10.992484 16.377281 11.460188 17 12.117188 17 C 12.594188 17 12.981703 16.657359 13.095703 16.193359 C 13.373703 15.069359 14.217016 14.238687 15.166016 13.304688 C 16.490016 12.001688 17.99 10.525391 18 8.0253906 C 18.011 5.2883906 16.274906 2.9158438 13.628906 2.2148438 C 13.179656 2.0958438 12.733854 2.0290352 12.298828 2.0078125 z M 11.5 20 C 11.224 20 11 20.224 11 20.5 L 11 21.5 C 11 21.776 11.224 22 11.5 22 L 12.5 22 C 12.776 22 13 21.776 13 21.5 L 13 20.5 C 13 20.224 12.776 20 12.5 20 L 11.5 20 z" />
    </svg>
  );
}
