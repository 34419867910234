// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import {
  ApiCall,
  ApiDataResponse,
  ApiError,
  ApiResponse,
  UseApiHelperReturn,
} from "types/api";
import { useState } from "react";
import ApiErrors from "components/ApiErrors";
import { useApiCallParams, useJwtStore } from "../gf-app-context";

interface State<T> {
  apiResponse: ApiResponse<T> | null;
  loaded: boolean;
  processing: boolean;
}

export function useApiHelper<T>(): UseApiHelperReturn<T> {
  const initialState = {
    apiResponse: null,
    loaded: false,
    processing: false,
  } as State<T>;
  const [state, setState]: [State<T>, (v: State<T>) => void] =
    useState(initialState);
  const { apiResponse, processing } = state;
  const apiCall = useApiCallParams();
  const jwtStore = useJwtStore();

  async function handleResponse(promise: Promise<ApiResponse<T>>) {
    setState({ ...state, processing: true });
    let apiResponse = await promise;
    if (apiResponse.error === true && apiResponse?.status === 401) {
      jwtStore.clearJwt();
    }

    setState({ ...state, loaded: true, processing: false, apiResponse });
    return apiResponse;
  }

  async function wrapCall(
    callFn: (call: ApiCall) => Promise<ApiResponse<T>>
  ): Promise<ApiResponse<T>> {
    return handleResponse(callFn(apiCall));
  }

  const errorResponse = (
    apiResponse?.error ? apiResponse : null
  ) as ApiError | null;
  const successResponse = (
    apiResponse?.error ? null : apiResponse
  ) as ApiDataResponse<T> | null;

  return {
    handleResponse,
    apiResponse,
    errorResponse,
    successResponse,
    loaded: state.loaded,
    processing,
    reset: () => setState(initialState),
    renderApiErrors: () => <ApiErrors error={errorResponse} />,
    wrapCall,
  };
}
