import { UseFormReturn } from "react-hook-form";
import { ErrorMessage, RowLabel, FormRow } from ".";

interface Props {
  children: React.ReactNode;
  errorMessages?: { [index: string]: string };
  hookForm?: UseFormReturn<any>;
  label?: string;
  name: string;
  errors?: any;
}

export default function FieldEditRow(props: Props) {
  const { children, errorMessages, hookForm, label, name } = props;
  const errors = props.errors || hookForm?.formState?.errors;

  return (
    <FormRow>
      {label && <RowLabel>{label}</RowLabel>}
      {children}
      <ErrorMessage errors={errors} name={name} messages={errorMessages} />
    </FormRow>
  );
}
